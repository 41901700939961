import {
  journalEntryEntityType_enum,
  journalEntry_bool_exp,
  party_enum,
} from '@finalytic/graphql';
import { Maybe, day } from '@finalytic/utils';

export const whereJournalEntries = ({
  date,
  tenantId,
  type,
  party,
  search,
  listingId,
}: {
  date: Maybe<string>;
  tenantId: string;
  type?: Maybe<journalEntryEntityType_enum>;
  search?: Maybe<string>;
  party?: Maybe<party_enum>;
  listingId?: Maybe<string>;
}): journalEntry_bool_exp => {
  const dates = date?.split('...');

  const start = dates?.[0]
    ? day(dates[0]).startOf('day').yyyymmdd()
    : undefined;
  const e = dates?.[1] ? day(dates[1]).add(1, 'day').yyyymmdd() : undefined;
  const end = e || day(start).add(1, 'day').yyyymmdd();

  return {
    tenantId: { _eq: tenantId },
    status: { _neq: 'archived' },
    entityType: type && type !== 'recurringFee' ? { _eq: type } : undefined,
    recurringFeeId: type ? { _is_null: type !== 'recurringFee' } : undefined,
    txnAt: date
      ? {
          _gte: start,
          _lt: end,
        }
      : undefined,
    party: party ? { _eq: party as party_enum } : undefined,
    listingOwnershipPeriod: listingId
      ? {
          listingId: { _eq: listingId },
        }
      : undefined,
    _or: search
      ? [
          { description: { _ilike: `%${search}%` } },
          { reservation: { confirmationCode: { _ilike: `%${search}%` } } },
        ]
      : undefined,
  };
};
