import { useClerk } from '@clerk/clerk-react';
import {
  useEnabledFeatures,
  useQuery,
  useSubscription,
  useTeam,
} from '@finalytic/data';
import { ComponentProps, useEffect } from 'react';
import { Navigate, Outlet, Route, Routes, useLocation } from 'react-router-dom';
import {
  Layout,
  OnboardingTeamSwitch,
  SidebarConnect,
  SidebarPricing,
} from './components';
import { useIntegrationHover } from './hooks';
import { OnboardingConnectRoute } from './routes/OnboardingConnectRoute';
import { OnboardingPricingRoute } from './routes/OnboardingPricingRoute';

export const OnboardingRoutes = () => {
  const [{ propertyManagementSystems, id: teamId, billingSubscriptionStatus }] =
    useTeam();
  const { BILLING } = useEnabledFeatures();
  const location = useLocation();

  const hasPms = propertyManagementSystems.length > 0;

  const [, refetchTeam] = useTeam();

  const { data: subscription } = useSubscription(
    (q, args) => {
      const tenant = q.tenantById({
        id: args.teamId,
      });

      return {
        billingStatus:
          tenant?.billingSubscriptionStatus as typeof billingSubscriptionStatus,
      };
    },
    {
      teamId,
    },
    {
      skip: !teamId,
    }
  );

  const billingStatus =
    subscription?.billingStatus || billingSubscriptionStatus;

  useEffect(() => {
    if (subscription?.billingStatus && BILLING) {
      refetchTeam();
    }
  }, [subscription?.billingStatus, BILLING]);

  return (
    <Routes>
      <Route element={<OnboardingLayout />}>
        {(!billingStatus || billingStatus === 'pending') && BILLING ? (
          <>
            <Route path="/trial" element={<OnboardingPricingRoute />} />
            <Route path="/*" element={<Navigate to="/trial" replace />} />
          </>
        ) : (
          <>
            <Route
              path="/connect-pms"
              element={<OnboardingConnectRoute type="PMS" />}
            />
            <Route
              path="/connect-accounting"
              element={<OnboardingConnectRoute type="Accounting" />}
            />

            <Route
              path="/*"
              element={
                <Navigate
                  to={
                    hasPms
                      ? `/connect-accounting${location.search}`
                      : `/connect-pms${location.search}`
                  }
                />
              }
            />
          </>
        )}
      </Route>
      <Route
        path="/callback"
        element={
          <Navigate to={`/connect-pms${location.search || ''}&callback=true`} />
        }
      />
    </Routes>
  );
};

type SidebarProps = Pick<
  ComponentProps<typeof Layout>,
  'sidebar' | 'sidebarPaddingTop' | 'title'
>;

const OnboardingLayout = () => {
  const { signOut } = useClerk();

  const [team] = useTeam();

  const { propertyManagementSystems } = team;

  const location = useLocation();

  const { accounting, pms } = useIntegrationHover();

  const { data } = useQuery(
    (q, args) => {
      const teams = q
        .tenant({
          order_by: [{ name: 'asc' }],
          where: { status: { _neq: 'deleted' } },
        })
        .map((i) => ({ id: i.id, name: i.name, type: i.type })) as {
        id: string;
        name: string;
        type: string;
      }[];

      if (args.pmsId) {
        const p = q.connectionById({ id: args.pmsId });

        const pms = {
          id: p?.id,
          name: p?.name || '',
          icon: p?.app.iconRound || '',
        };

        return {
          teams,
          pms,
        };
      }

      return { teams };
    },
    {
      variables: {
        pmsId: propertyManagementSystems[0]?.id,
      },
    }
  );

  const side: Record<string, SidebarProps> = {
    '/trial': {
      title: 'Unlock Your 14-Day Free Trial with VRPlatform',
      sidebar: <SidebarPricing />,
      sidebarPaddingTop: '20vh',
    },
    '/connect-pms': {
      title: 'Connect your PMS',
      sidebar: (
        <SidebarConnect
          pms={data?.pms || pms}
          accounting={accounting}
          title="Choose your PMS"
          subtitle="We will collect, clean and organize your data. So you can derive valuable insights and take action on your findings."
        />
      ),
    },
    '/connect-accounting': {
      title: 'Connect your Accounting',
      sidebar: (
        <SidebarConnect
          pms={data?.pms || pms}
          accounting={accounting}
          title="Choose your Accounting"
          subtitle="We will collect, clean and organize your data. So you can derive valuable insights and take action on your findings."
        />
      ),
    },
  };

  return (
    <Layout
      showHelpCenter
      handleLogout={signOut}
      // setTeam={setTeam}
      // teams={data?.teams || []}
      // team={team}
      teamSelectComponent={<OnboardingTeamSwitch />}
      {...side[location.pathname]}
    >
      <Outlet />
    </Layout>
  );
};
