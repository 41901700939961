import { Filter } from '@finalytic/components';
import {
  useEnabledFeatures,
  useInfiniteQuery,
  useMe,
  useQuery,
  useTeamId,
} from '@finalytic/data';
import type {
  journalEntryEntityType_enum,
  journalEntry_bool_exp,
  listing_bool_exp,
} from '@finalytic/graphql';
import { type SelectItem, StringParam, useQueryParams } from '@finalytic/ui';
import { day, hasValue, toTitleCase } from '@finalytic/utils';
import { Group } from '@mantine/core';
import { getListingName, whereListings } from '@vrplatform/ui-common';
import { useMemo, useState } from 'react';

export const useGeneralLedgerDetailFilter = () => {
  const [filter, setFilter] = useQueryParams({
    search: StringParam,
    listingId: StringParam,
    party: StringParam,
    date: StringParam,
    type: StringParam,
  });

  const isFiltered = Object.values(filter).some(hasValue);

  return {
    filter,
    setFilter,
    isFiltered,
    reset: () =>
      setFilter({
        search: undefined,
        listingId: undefined,
        party: undefined,
        date: undefined,
        type: undefined,
      }),
  };
};

export const GeneralLedgerDetailFilter = () => {
  return (
    <Group>
      <SearchFilter />
      <DateFilter />
      <ListingFilter />
      <TypeFilter />
      <PartyFilter />
    </Group>
  );
};

const SearchFilter = () => {
  const { filter, setFilter } = useGeneralLedgerDetailFilter();

  return (
    <Filter.Search
      value={filter.search || ''}
      setValue={(v) => setFilter({ search: v })}
    />
  );
};

const TypeFilter = () => {
  const { filter, setFilter } = useGeneralLedgerDetailFilter();

  const options = useMemo<SelectItem<journalEntryEntityType_enum>[]>(
    () => [
      {
        label: 'Transaction',
        value: 'transaction',
      },
      {
        label: 'Reservation',
        value: 'reservation',
      },
      {
        label: 'Fee & Commission',
        value: 'recurringFee',
      },
      {
        label: 'Owner Statement',
        value: 'statement',
      },
    ],
    []
  );

  const value = options.find((i) => i.value === filter.type) || null;

  return (
    <Filter.Select
      value={value}
      label="Type"
      setValue={(v) => setFilter({ type: v?.value })}
      type="single"
      data={{
        options,
      }}
    />
  );
};

const DateFilter = () => {
  const { filter, setFilter } = useGeneralLedgerDetailFilter();

  return (
    <Filter.Date
      value={filter.date || undefined}
      setValue={(value) => {
        setFilter({
          date: value
            ?.filter(hasValue)
            .map((date) => day(date).yyyymmdd())
            .join('...'),
        });
      }}
    />
  );
};

const ListingFilter = () => {
  const [teamId] = useTeamId();
  const { filter, setFilter } = useGeneralLedgerDetailFilter();
  const { id: meId } = useMe();
  const [search, setSearch] = useState('');
  const { GL } = useEnabledFeatures();

  const queryData = useInfiniteQuery(
    (q, { teamId, search, meId, GL }, { limit, offset }) => {
      const where: listing_bool_exp = whereListings({
        currentTeamId: teamId,
        dashboard: 'propertyManager',
        partnerTeamIds: [],
        search,
        meId,
        GL,
      });

      const list = q
        .listings({
          where,
          order_by: [{ calculated_title: 'asc_nulls_last' }],
          limit,
          offset,
        })
        .map<SelectItem>((res) => ({
          value: res.id,
          label: getListingName(res),
        }));

      const aggregate = q.listingAggregate({ where }).aggregate?.count() || 0;

      return {
        list,
        aggregate,
      };
    },
    {
      skip: !teamId,
      queryKey: ['listings'],
      variables: {
        teamId,
        search: search?.trim(),
        meId,
        GL,
      },
    }
  );

  const { data } = useQuery(
    (q, { listingId }) => {
      if (!listingId) return null;

      const value =
        q
          .listings({ where: { id: { _eq: listingId } } })
          .map<SelectItem>((item) => ({
            label: getListingName(item),
            value: item.id,
          }))[0] || null;

      return {
        value,
      };
    },
    {
      skip: !teamId,
      queryKey: ['listings'],
      keepPreviousData: true,
      variables: {
        listingId: filter.listingId,
      },
    }
  );

  const value = data?.value || null;

  return (
    <Filter.Select
      value={value}
      setValue={(v) => setFilter({ listingId: v?.value })}
      type="single"
      label="Listing"
      withinPortal
      infiniteData={{ ...queryData, setSearch }}
    />
  );
};

const PartyFilter = () => {
  const [teamId] = useTeamId();
  const { filter, setFilter } = useGeneralLedgerDetailFilter();

  const queryData = useInfiniteQuery(
    (q, { teamId }, { limit, offset }) => {
      const where: journalEntry_bool_exp = {
        party: { _is_null: false },
        tenantId: { _eq: teamId },
      };

      const list = q
        .journalEntries({
          where,
          order_by: [{ party: 'asc_nulls_last' }],
          limit,
          offset,
          distinct_on: ['party'],
        })
        .map<SelectItem>((res) => ({
          value: res.party!,
          label: toTitleCase(res.party)!,
        }));

      const aggregate =
        q
          .journalEntryAggregate({ where, distinct_on: ['party'] })
          .aggregate?.count() || 0;

      return {
        list,
        aggregate,
      };
    },
    {
      skip: !teamId,
      queryKey: ['journalEntries'],
      variables: {
        teamId,
      },
    }
  );

  const { data } = useQuery(
    (q, { party }) => {
      if (!party) return null;

      const value =
        q
          .parties({ where: { name: { _eq: party } } })
          .map<SelectItem>((item) => ({
            label: toTitleCase(item.name),
            value: item.name!,
          }))[0] || null;

      return {
        value,
      };
    },
    {
      skip: !teamId,
      queryKey: ['journalEntries'],
      keepPreviousData: true,
      variables: {
        party: filter.party,
      },
    }
  );

  const value = data?.value || null;

  return (
    <Filter.Select
      value={value}
      setValue={(v) => setFilter({ party: v?.value })}
      type="single"
      label="Party"
      withinPortal
      hideSearch
      infiniteData={{ ...queryData, setSearch: () => {} }}
    />
  );
};
