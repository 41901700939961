import { z } from 'zod';

// Zod parsing
// Payout ID,Payout Date,Payout Amount,Currency,Type,Description,Confirmation Code,Amount
// 123,2023-01-01,1000,EUR,payout,,
// 123,2023-01-01,1000,EUR,reservation,,ABC-123,300
// 123,2023-01-01,1000,EUR,reservation,,ABC-234,800
// 123,2023-01-01,1000,EUR,resolution,"Some Explanation",ABC-234,-100

export const booking_csv_import_schema = z
  .object({
    'Payout ID': z.coerce.string({ required_error: 'Payout ID is required' }),
    'Payout date': z.string().refine(
      (date) => {
        return date.match(/^\d{4}-\d{2}-\d{2}$/);
      },
      {
        message: 'Please provide a date in the format YYYY-MM-DD',
      }
    ),
    'Payable amount': z
      .number({
        required_error: 'Payable amount is required',
      })
      .or(
        z
          .string({
            required_error: 'Payable amount is required',
          })
          .transform(() => 0)
      )
      .nullable(),
    'Payout amount': z
      .number()
      .or(z.string().transform(() => 0))
      .nullish(),
    'Paid amount': z
      .number()
      .or(z.string().transform(() => 0))
      .nullish(),
    'Gross amount': z
      .number({
        required_error: 'Gross amount is required',
      })
      .or(
        z
          .string({
            required_error: 'Gross amount is required',
          })
          .transform(() => 0)
      ),
    'City tax': z
      .number()
      .or(z.string().transform(() => 0))
      .nullish(),
    Commission: z
      .number()
      .or(z.string().transform(() => 0))
      .nullable(),
    'Payment fee': z
      .number()
      .or(z.string().transform(() => 0))
      .nullable(),
    Currency: z
      .string({
        invalid_type_error: 'Currency must be a string',
      })
      .nullish(),
    'Payout currency': z
      .string({
        invalid_type_error: 'Currency must be a string',
      })
      .nullish(),
    'Type / Transaction type': z.string({
      invalid_type_error:
        'Type / Transaction type must be "(Payout)", "Reservation" ...',
      required_error: 'Type / Transaction type is required',
    }),
    'Property name': z.string().nullable(),
    'Reference number': z.string().or(z.number()).nullable(),
  })
  .refine(
    (v) => v['Payout amount'] === undefined || v['Paid amount'] === undefined,
    'Either Payout amount or Paid amount  must be provided'
  )
  .refine(
    (v) => !!v.Currency || !!v['Payout currency'],
    'Either Currency or Payout Currency must be provided'
  );

export const deposits_csv_import_schema = z.object({
  'Payout ID': z.coerce.string({ required_error: 'Payout ID is required' }),
  'Payout Date': z.string().refine(
    (date) => {
      return date.match(/^\d{4}-\d{2}-\d{2}$/);
    },
    {
      message: 'Please provide a date in the format YYYY-MM-DD',
    }
  ),
  'Payout Amount': z.number({
    required_error: 'Payout Amount is required',
    invalid_type_error: 'Payout Amount must be a number',
  }),
  Currency: z.string({
    required_error: 'Currency is required',
    invalid_type_error: 'Currency must be a string',
  }),
  Type: z.enum(['payout', 'reservation'], {
    invalid_type_error: 'Type must be either "payout" or "reservation"',
    required_error: 'Type is required',
  }),
  Description: z.string().nullable(),
  'Confirmation Code': z.string().nullable(),
  Amount: z.number().nullable(),
});
