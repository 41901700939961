import { ChevronIcon, CrossIcon } from '@finalytic/icons';
import { IconButton, LoadingIndicator, SelectItem } from '@finalytic/ui';
import { Maybe } from '@finalytic/utils';
import {
  Center,
  Group,
  Text,
  Tooltip,
  rem,
  useMantineColorScheme,
  useMantineTheme,
} from '@mantine/core';
import { Badge } from '../Badge';
import { Select, SelectInputProps, SelectProps } from '../select';

export const InputSelect = <TSelectValue extends string = string>(
  props: SelectProps<TSelectValue>
) => {
  return (
    <Select {...props}>{(target) => <InputSelectTarget {...target} />}</Select>
  );
};

export const InputSelectTarget = <TSelectValue extends string = string>({
  setValue,
  disabled,
  loadingMutation,
  loadingQuery,
  opened,
  placeholder,
  withClearButton,
  withBorder = true,
  type,
  value,
  width,
  customMultiPill: CustomMultiPill,
  customSingleText: CustomSingleText,
  placeholderColor,
  error,
}: SelectInputProps<TSelectValue> & {
  customMultiPill?: (item: SelectItem<TSelectValue>) => JSX.Element;
  customSingleText?: (o: {
    item: SelectItem<TSelectValue> | null;
  }) => JSX.Element;
}) => {
  const loading = loadingMutation || loadingQuery;

  const { colors } = useMantineTheme();
  const { colorScheme } = useMantineColorScheme();

  const target = (
    <Group
      wrap="nowrap"
      justify="space-between"
      id="input-target"
      sx={(theme) => {
        const isDarkTheme = colorScheme === 'dark';

        const borderColor = error ? 'red' : theme.primaryColor;

        return {
          width: width || undefined,
          border: '1px solid',
          borderColor: opened
            ? theme.colors[borderColor][6]
            : withBorder
              ? error
                ? theme.colors.red[6]
                : theme.colors.gray[isDarkTheme ? 8 : 4]
              : 'transparent',
          boxShadow: opened
            ? `0px 0px 0px 2px ${theme.colors[borderColor][4]}40`
            : undefined,
          borderRadius: theme.radius.md,
          overflow: 'hidden',
          padding:
            type === 'single'
              ? `2px ${theme.spacing.sm}`
              : CustomMultiPill
                ? '4px 5px'
                : '4px 10px',
          minHeight: '2.25rem',
          cursor: disabled ? undefined : 'pointer',
          backgroundColor:
            loading || disabled
              ? isDarkTheme
                ? theme.colors.gray[8]
                : theme.colors.neutral[0]
              : isDarkTheme
                ? theme.colors.gray[9]
                : theme.white,
        };
      }}
    >
      {loadingQuery && value ? (
        <LoadingIndicator size="xs" sx={{ justifySelf: 'flex-end' }} />
      ) : (
        <>
          {type === 'single' ? (
            CustomSingleText ? (
              <CustomSingleText item={value} />
            ) : (
              <ValueLabel
                label={value?.label}
                placeholder={placeholder}
                placeholderColor={placeholderColor}
                loadingValue={!!loadingQuery}
                icon={value?.icon}
              />
            )
          ) : (
            <Group
              gap={rem(8)}
              my={3}
              pl={!value.length ? 4 : 1}
              sx={{
                maxWidth: '90%',
              }}
            >
              {value?.length ? (
                value.map((item) =>
                  CustomMultiPill ? (
                    <CustomMultiPill key={item.value} {...item} />
                  ) : (
                    <ValuePill key={item.value} value={item} />
                  )
                )
              ) : (
                <ValueLabel
                  label={null}
                  placeholder={placeholder}
                  placeholderColor={placeholderColor}
                  icon={null}
                  loadingValue={!!loadingQuery}
                />
              )}
            </Group>
          )}
          {loadingMutation ? (
            <LoadingIndicator size="xs" />
          ) : type === 'single' && withClearButton && value && !disabled ? (
            <IconButton
              w={18}
              p={0}
              sx={{
                flexShrink: 0,
                minWidth: 0,
              }}
              ref={(ref: HTMLButtonElement) => {
                if (!ref) return;

                ref.onclick = (e) => {
                  e.stopPropagation(); // this works

                  // remove value
                  if (type === 'single') {
                    setValue(null, { type: 'removed', value });
                  }
                };
              }}
            >
              <CrossIcon size={18} color={colors.neutral[4]} />
            </IconButton>
          ) : (
            !disabled && (
              <ChevronIcon
                size={18}
                color={colors.gray[4]}
                sx={{
                  flexShrink: 0,
                  marginLeft: 'auto',
                }}
              />
            )
          )}
        </>
      )}
    </Group>
  );

  if (typeof error === 'string') {
    return (
      <Tooltip label={error} withArrow>
        {target}
      </Tooltip>
    );
  }

  return target;
};

const ValuePill = ({ value }: { value: SelectItem }) => {
  return (
    <Badge
      sx={{
        maxWidth: 140,
        margin: 0,
      }}
      leftIcon={value?.icon}
    >
      {value.label}
    </Badge>
  );
};

const ValueLabel = ({
  label,
  placeholder,
  placeholderColor,
  loadingValue,
  icon,
}: {
  label: Maybe<string>;
  icon: Maybe<React.ReactNode>;
  placeholder: Maybe<string>;
  placeholderColor: string | undefined;
  loadingValue: boolean;
}) => {
  const { colors } = useMantineTheme();

  if (loadingValue)
    return <LoadingIndicator sx={{ justifySelf: 'flex-end' }} size="xs" />;

  if (!label && !placeholder) return null;

  if (icon) {
    return (
      <Group wrap="nowrap" gap="xs">
        <Center sx={{ flexShrink: 0 }}>{icon}</Center>
        <Text
          component="span"
          size="sm"
          c={label ? undefined : placeholderColor || colors.gray[5]}
          lineClamp={3}
        >
          {label || placeholder}
        </Text>
      </Group>
    );
  }

  return (
    <Text
      component="span"
      size="sm"
      c={label ? undefined : placeholderColor || colors.gray[5]}
      lineClamp={3}
    >
      {label || placeholder}
    </Text>
  );
};
