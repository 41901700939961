import {
  Button,
  InputDay,
  InputSelect,
  InputWrapper,
} from '@finalytic/components';
import {
  useQuery,
  useTeamId,
  useTrpcMutation,
  useTrpcQuery,
} from '@finalytic/data';
import { useRunDrawer } from '@finalytic/data-ui';
import { Modal, SelectItem, showErrorNotification } from '@finalytic/ui';
import { Maybe, day, emptyUUID, isUUID, toTitleCase } from '@finalytic/utils';
import {
  Avatar,
  Box,
  Center,
  Group,
  Skeleton,
  Stack,
  Text,
} from '@mantine/core';
import { useDebouncedValue } from '@mantine/hooks';
import { useEffect, useMemo } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useExtractModal } from './useExtractModal';

// allow to extract all of connection
// allow to extract only certain key from connection
// prefill modal when opening from mapping
// some systems allow extracting by date ranges

const extractAllKey = 'extract_all';

type FormInputs = {
  forceUpdate: Maybe<boolean>;
  extractType: Maybe<string>;
  dateRange: [Date | null, Date | null] | undefined | null;
};

export const ExtractModal = () => {
  const {
    close,
    opened,
    data: { extractConnectionId, extractType },
  } = useExtractModal();

  const methods = useForm<FormInputs>({ mode: 'onChange' });

  const { runExtraction } = useExtractMutation({
    closeModal: close,
    extractConnectionId,
  });

  const { connection, extractors, loading, refetch } = useConnectionData({
    extractConnectionId,
    opened,
  });

  useEffect(() => {
    if (opened) {
      methods.reset({ extractType, dateRange: [null, null] });
    }
  }, [extractType, opened]);

  useEffect(() => {
    if (extractType && !extractors.map((x) => x.name).includes(extractType)) {
      methods.setValue('extractType', null);
    }
  }, [extractType, extractors]);

  return (
    <Modal
      opened={opened}
      onClose={close}
      title={
        loading ? (
          ''
        ) : connection ? (
          <Group wrap="nowrap">
            <Avatar size="sm" src={connection.logo} />
            <Text lineClamp={1}>{connection.name}</Text>
          </Group>
        ) : (
          <Text>Missing connection</Text>
        )
      }
    >
      {loading ? (
        <Stack mb="lg">
          <Skeleton height={40} />
          <Skeleton height={40} />
          <Skeleton height={40} />
          <Skeleton height={40} />
        </Stack>
      ) : !connection ? (
        <Center mih={300} sx={{ flexDirection: 'column' }}>
          <Text ta="center" component="h3" mx="auto" mb={0}>
            Failed to fetch connection
          </Text>
          <Text ta="center" component="p" mx="auto">
            An error has occurred. Please try fetching query again.
          </Text>
          <Group>
            <Button onClick={close}>Cancel</Button>
            <Button variant="primary" onClick={refetch}>
              Refetch connection
            </Button>
          </Group>
        </Center>
      ) : (
        <Box component="form" onSubmit={methods.handleSubmit(runExtraction)}>
          <Stack mb="lg">
            {/* Select for extractTypes */}
            <Controller
              control={methods.control}
              name="extractType"
              rules={{
                required: 'Data type is required',
              }}
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => {
                const data = useMemo<SelectItem[]>(
                  () =>
                    extractors.map((ex) => ({
                      label: toTitleCase(ex.name) || '',
                      value: ex.name,
                    })),
                  [extractors]
                );

                const selectValue = useMemo<SelectItem | undefined>(
                  () => data.find((d) => d.value === value),
                  [data, value]
                );

                return (
                  <InputWrapper
                    label="Data type"
                    error={error?.message}
                    required
                  >
                    <InputSelect
                      data={{ options: data }}
                      value={selectValue || null}
                      type="single"
                      setValue={(val) => onChange(val?.value || null)}
                      inputProps={{
                        placeholder: 'Data type',
                        error: !!error,
                      }}
                      pinnedItems={[
                        {
                          label: 'Fetch all types',
                          value: extractAllKey,
                        },
                      ]}
                      dropdownProps={{
                        searchPlaceholder: 'Search data type...',
                        width: 'target',
                        withinPortal: true,
                      }}
                    />
                  </InputWrapper>
                );
              }}
            />
            <Controller
              control={methods.control}
              name="dateRange"
              render={({
                field: { name, onChange, value },
                fieldState: { error },
              }) => {
                return (
                  <InputWrapper label="Date range" error={error?.message}>
                    <InputDay
                      type="range"
                      value={value || undefined}
                      name={name}
                      onChange={onChange}
                      error={!!error?.message}
                      placeholder="Date range (optional)"
                      clearable
                      allowSingleDateInRange
                      popoverProps={{
                        withinPortal: true,
                      }}
                    />
                  </InputWrapper>
                );
              }}
            />
            {/*<HiddenFeatureIndicator permission="super-admin">
              <Input.Wrapper label="">
                <Controller
                  control={methods.control}
                  name="forceUpdate"
                  render={({ field: { name, onChange, value } }) => {
                    return (
                      <Switch
                        checked={value || undefined}
                        name={name}
                        onChange={onChange}
                        disabled={loading}
                        label="Force update even if no changes detected"
                      />
                    );
                  }}
                />
              </Input.Wrapper>
            </HiddenFeatureIndicator>*/}
          </Stack>

          <Group justify="right" mb="md">
            <Button type="button" onClick={close}>
              Cancel
            </Button>
            <Button
              variant="primary"
              loading={methods.formState.isSubmitting}
              type="submit"
            >
              Fetch data
            </Button>
          </Group>
        </Box>
      )}
    </Modal>
  );
};

const useExtractMutation = ({
  extractConnectionId,
  closeModal,
}: { extractConnectionId: Maybe<string>; closeModal: () => void }) => {
  const [teamId] = useTeamId();
  const { setWorkflowIds } = useRunDrawer();

  const { mutate } = useTrpcMutation('extract');

  const runExtraction = async (data: FormInputs) => {
    try {
      if (!extractConnectionId)
        return showErrorNotification({
          message: 'Missing connection id',
          color: 'yellow',
        });

      const range =
        data.dateRange?.every((i) => !!i) && data.dateRange.length === 2;

      const extractType = data.extractType || undefined;
      const isExtractAll = extractType === extractAllKey;

      const res = await mutate({
        teamId,
        connectionId: extractConnectionId,
        type: isExtractAll ? undefined : extractType,
        forceUpdate: true, //data.forceUpdate || false,
        range: range
          ? {
              start: day(data.dateRange![0]!).yyyymmdd(),
              end: day(data.dateRange![1]!).yyyymmdd(),
            }
          : undefined,
      });

      closeModal();
      setWorkflowIds(res.workflowId, res.syncId);
    } catch (error) {
      console.error(error);
    }
  };

  return {
    runExtraction,
  };
};

const useConnectionData = ({
  extractConnectionId,
  opened,
}: { extractConnectionId: Maybe<string>; opened: boolean }) => {
  const disableQueries = !extractConnectionId || !opened;

  const [teamId] = useTeamId();

  const {
    data,
    loading: loadingSchema,
    refetch: refetchSchema,
  } = useTrpcQuery(
    'extractSchema',
    {
      teamId,
      connectionId: extractConnectionId!,
    },
    { skip: disableQueries }
  );

  const extractors = data?.extractors ?? [];

  const {
    data: queryConnection,
    isLoading: loadingQuery,
    refetch: refetchQuery,
  } = useQuery(
    (q, args) => {
      if (!args.connectionId || !isUUID(args.connectionId)) return null;

      const connection = q.connectionById({
        id: args.connectionId || emptyUUID,
      });

      return {
        id: connection?.id,
        name: connection?.name,
        logo: connection?.app?.iconRound,
      };
    },
    {
      skip: disableQueries,
      variables: {
        connectionId: extractConnectionId,
      },
    }
  );

  const [debouncedConnection] = useDebouncedValue(queryConnection, 300);

  const connection = queryConnection || debouncedConnection;

  const loading = loadingSchema || loadingQuery;

  const refetch = () => {
    refetchSchema();
    refetchQuery();
  };

  return {
    loading,
    connection,
    extractors,
    refetch,
  };
};
