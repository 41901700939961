import {
  Badge,
  InputPercentage,
  InputSelect,
  Select,
} from '@finalytic/components';
import {
  gqlV2,
  useDashboard,
  useEnabledFeatures,
  useInfiniteQuery,
  useMe,
  useMutation,
  useQuery,
  useTeamId,
  useTracking,
  useTrpcMutation,
} from '@finalytic/data';
import { HiddenFeatureIndicator } from '@finalytic/data-ui';
import {
  BriefcaseIcon,
  CopyIcon,
  Edit3Icon,
  EmailIcon,
  EyeIcon,
  HashtagIcon,
  Icon,
  LoaderIcon,
  PhoneIcon,
  PinIcon,
  PlusIcon,
} from '@finalytic/icons';
import { MRT_ColumnDef } from '@finalytic/table';
import {
  Drawer,
  EllipsisMenuDangerItem,
  EllipsisMenuDivider,
  EllipsisMenuItem,
  IconButton,
  SelectItem,
  StringParam,
  showErrorNotification,
  showSuccessNotification,
  useQueryParamSet,
} from '@finalytic/ui';
import { Maybe, day, ensure, hasValue, sortBy, utc } from '@finalytic/utils';
import { Box, Center, Group, LoadingOverlay, Stack, Text } from '@mantine/core';
import {
  useClipboard,
  useDebouncedState,
  useDebouncedValue,
} from '@mantine/hooks';
import {
  formatAddress,
  formatOwnerName,
  formatPercentage,
  formatUserName,
  getCountryByIsoCode,
  getListingName,
  getOwnerAddress,
  getOwnerCompanyType,
  getStateByIsoCode,
  whereListingPeriod,
  whereListings,
  whereOwnerUsers,
} from '@vrplatform/ui-common';
import { useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router';
import { ListingOwnerBadge, VendorMappingSelect } from '../../components';
import { OwnerStatusBadge } from '../../views/owners/_components/OwnerStatusSelect';
import { StatementStatusBadge } from '../../views/statements/_components';
import { TaxStatementAmountCell } from '../../views/statements/statement-list/tax-statement-list-table/TaxStatementAmountCell';
import { TaxStatementStatusSelect } from '../../views/statements/statement-list/tax-statement-list-table/TaxStatementStatusSelect';
import { useTaxAccountCollectionQuery } from '../../views/statements/statement-list/tax-statement-list-table/useTaxAccountCollectionQuery';
import { getTaxStatement } from '../../views/statements/statement-list/tax-statement-list-table/useTaxStatementListTableQuery';
import {
  DrawerCollapsableTable,
  DrawerHeader,
  DrawerInfoCard,
} from '../_components';
import { OwnerEditForm } from './AddOwnerDrawer';
import { OwnerEllipsisMenuItems } from './OwnerEllipsisMenuItems';
import { OwnerEllipsisMenuModals } from './OwnerEllipsisMenuModals';
import { useOwnerDetailDrawer } from './useOwnerDetailDrawer';
import { useOwnerUserDetailDrawer } from './useOwnerUserDetailDrawer';

function useOwnerQuery(id: Maybe<string>) {
  const [teamId] = useTeamId();

  const query = useQuery(
    (q, args) => {
      if (!args.id) return null;

      return (
        q
          .owners({
            where: {
              id: { _eq: args.id },
            },
          })
          .map((owner) => {
            const address = getOwnerAddress(owner).values;

            const currentPeriodMemberships = owner
              .ownershipPeriodMemberships({
                where: {
                  period: whereListingPeriod({
                    date: day().yyyymmdd(),
                  }),
                },
              })
              .map((member) => ({
                id: member.id,
                listingName: getListingName(member.period.listing),
                listingId: member.period.listingId,
                split: member.split as Maybe<number>,
              }));

            const ownerships = owner
              .ownerships({
                order_by: [
                  {
                    createdAt: 'asc_nulls_last',
                  },
                ],
              })
              .map<Ownership>((ownership) => ({
                id: ownership.id,
                listing: {
                  id: ownership.listing.id,
                  name: getListingName(ownership.listing),
                  address: ownership.listing.address,
                },
                split: ownership.split,
              }));

            const getIsArchived = () => {
              if (owner.status === 'inactive') {
                return true;
              }

              if (!owner.status && owner.pmsStatus === 'inactive') {
                return true;
              }

              return false;
            };

            return {
              id: owner.id,
              taxId: owner.taxId,
              status: owner.status,
              currentPeriodMemberships,
              pmsStatus: owner.pmsStatus,
              isArchived: getIsArchived(),
              tenantId: owner.tenantId,
              addressId: owner.addressId,
              email: owner.email,
              phone: owner.phone,
              type: owner.type || 'individual',
              companyType: getOwnerCompanyType(owner.companyType),
              name: formatOwnerName(owner),
              firstName: owner.firstName,
              lastName: owner.name,
              companyName: owner.name,
              teamId: owner.tenantId,
              address,
              ownerships,
              hasOwnerships: ownerships.length > 0,
              userAccesses: sortBy(
                owner
                  .userAccesses({
                    order_by: [
                      {
                        user: {
                          firstName: 'asc_nulls_last',
                        },
                      },
                    ],
                    where: {
                      user: {
                        memberships: {
                          tenantId: { _eq: args.teamId },
                        },
                      },
                    },
                  })
                  .map((access) => {
                    return {
                      id: access.id,
                      userId: access.userId,
                      name: formatUserName(access.user),
                      email: access.user.email,
                      role: access.role,
                    };
                  }),
                'name'
              ),
              ownerStatements: owner
                .statementOwnerships({
                  order_by: [
                    {
                      statement: {
                        startAt: 'desc_nulls_last',
                      },
                    },
                  ],
                })
                .map((statementOwner) => ({
                  id: statementOwner.statementId,
                  startAt: statementOwner.statement.startAt,
                  status: statementOwner.statement.status,
                  listing: {
                    id: statementOwner.statement?.listing?.id,
                    name: statementOwner.statement?.listing
                      ? getListingName(statementOwner.statement?.listing)
                      : '',
                  },
                  statementOwner: {
                    id: statementOwner.id,
                    role: ensure<gqlV2.listing_owner_role_enum | 'company'>(
                      statementOwner.owner?.type === 'company'
                        ? 'company'
                        : statementOwner.role || 'owner'
                    ),
                    name: formatUserName(statementOwner.owner),
                  },
                })),
            };
          })[0] || null
      );
    },
    {
      skip: !id,
      queryKey: 'owners',
      variables: {
        id,
        teamId,
      },
    }
  );

  const [debounced] = useDebouncedValue(query.data, 500);

  return { ...query, data: query.data || debounced };
}

type Owner = NonNullable<ReturnType<typeof useOwnerQuery>['data']>;

export const OwnerDetailDrawer = () => {
  const { opened, close, ownerId, view, setView } = useOwnerDetailDrawer();
  const { track } = useTracking();

  const [modalOpened, setModalOpened] = useState<{
    modal: 'delete' | 'archive' | 'migrate';
    owner: Owner;
  } | null>(null);

  const { isLoading, data: owner } = useOwnerQuery(ownerId);

  const { mutate } = useTrpcMutation('updateOwner', {
    invalidateQueryKeys: ['owners'],
  });

  const closeModal = () => setModalOpened(null);

  return (
    <>
      {ownerId && (
        <OwnerEllipsisMenuModals
          owner={owner}
          deleteModal={{
            closeModal,
            opened: modalOpened?.modal === 'delete' && !!modalOpened?.owner,
          }}
          archiveModal={{
            closeModal,
            opened: modalOpened?.modal === 'archive' && !!modalOpened?.owner,
          }}
          migrateModal={{
            closeModal,
            opened: modalOpened?.modal === 'migrate' && !!modalOpened?.owner,
          }}
        />
      )}
      <Drawer opened={opened} onClose={close} size={550}>
        <DrawerHeader
          closeDrawer={close}
          title={
            <Group gap="xs" wrap="nowrap">
              <Icon
                icon={owner?.type === 'company' ? 'OfficeIcon' : 'UserIcon'}
                size={22}
                color="gray"
              />
              <Text size="xl" fw={500} component="h3" m={0}>
                {owner?.name || 'Missing name'}
              </Text>
            </Group>
          }
          type="Owner"
          loading={isLoading}
          menuItems={
            view === 'overview' &&
            owner && (
              <OwnerEllipsisMenuItems
                openDeleteModal={() =>
                  setModalOpened({
                    modal: 'delete',
                    owner,
                  })
                }
                openArchiveModal={() =>
                  setModalOpened({
                    modal: 'archive',
                    owner,
                  })
                }
                openMigrationModal={() =>
                  setModalOpened({ modal: 'migrate', owner })
                }
                ownerId={owner.id}
                isArchived={owner.isArchived}
              />
            )
          }
          containerSx={
            view === 'edit'
              ? {
                  marginBottom: 0,
                }
              : undefined
          }
        />
        {!owner && !isLoading ? (
          'No owner found'
        ) : view === 'edit' && owner ? (
          <>
            <OwnerEditForm
              isOwnerAddModal={false}
              handleSubmit={async (values) => {
                if (!values.address.stateCode || !values.address.countryCode) {
                  return showErrorNotification({
                    message: 'Please select a country and state',
                    color: 'yellow',
                    icon: null,
                  });
                }

                const isIndividual = values.type === 'individual';

                return mutate({
                  ownerId: owner.id,
                  tenantId: owner.teamId,
                  input: {
                    firstName: values.firstName,
                    lastName: values.lastName,
                    email: values.email,
                    phone: values.phone,
                    taxId: values.taxId,
                    type: isIndividual ? 'individual' : 'company',
                    companyType: isIndividual ? null : values.type,
                    addressId: owner.addressId,
                    address: !values.address.line1?.trim()
                      ? null
                      : {
                          line1: values.address.line1,
                          line2: values.address.line2 || '',
                          city: values.address.city,
                          postcode: values.address.postcode,
                          countryCode: values.address.countryCode,
                          stateCode: values.address.stateCode || '',
                          country:
                            getCountryByIsoCode(values.address.countryCode)
                              ?.name || '',
                          state:
                            getStateByIsoCode(
                              values.address.stateCode,
                              values.address.countryCode
                            )?.name || '',
                        },
                  },
                }).then(() => {
                  track('owner_updated', {
                    tenantId: owner.teamId,
                    ownerId: owner.id,
                  });
                  setView('overview');
                });
              }}
              initialValues={{
                firstName: owner.firstName || '',
                lastName: owner.lastName || '',
                companyName: '', // legacy
                email: owner.email!,
                phone: owner.phone || '',
                taxId: owner.taxId || '',
                type:
                  owner.type === 'individual'
                    ? 'individual'
                    : owner.companyType?.value || 'individual',
                address: owner.address,
                addUserOption: false,
              }}
              onReset={() => setView('overview')}
              submitButtonLabel="Save changes"
              isEmailDisabled={false}
            />
          </>
        ) : (
          <OwnerDetail owner={owner} isLoading={isLoading} />
        )}
      </Drawer>
    </>
  );
};

const OwnerDetail = ({
  owner,
  isLoading,
}: { owner: Maybe<Owner>; isLoading: boolean }) => {
  const { GL } = useEnabledFeatures();
  if (!owner) return null;

  return (
    <Stack
      gap={'md'}
      mb="md"
      sx={{
        position: 'relative',
      }}
    >
      <DrawerInfoCard
        rows={[
          {
            icon: LoaderIcon,
            title: 'Status',
            text: (owner.status === 'inactive' ||
              owner.pmsStatus === 'inactive') && (
              <OwnerStatusBadge {...owner} />
            ),
          },
          {
            icon: BriefcaseIcon,
            title: 'Type',
            text: owner.companyType?.label,
          },
          {
            icon: HashtagIcon,
            title: 'Tax ID',
            text: owner.taxId?.trim() ? <TaxId taxId={owner.taxId} /> : null,
          },
          {
            icon: EmailIcon,
            title: 'Email',
            text: owner.email,
          },
          {
            icon: PhoneIcon,
            title: 'Phone',
            text: owner.phone,
          },
          {
            icon: PinIcon,
            title: 'Address',
            text: formatAddress(owner.address),
          },
        ]}
      />

      <VendorMappingSelect ownerId={owner.id} />

      <UserAccesses userAccesses={owner.userAccesses} ownerId={owner.id} />

      {GL ? (
        <ListingPeriod memberships={owner.currentPeriodMemberships} />
      ) : (
        <ListingOwnerships ownerId={owner.id} ownerships={owner.ownerships} />
      )}
      <OwnerStatementsTable
        statements={owner.ownerStatements}
        ownerId={owner.id}
      />

      <TaxStatementsTable
        ownerId={owner.id}
        ownerStatements={owner.ownerStatements}
      />

      <LoadingOverlay
        visible={isLoading}
        loaderProps={{
          size: 'sm',
        }}
      />
    </Stack>
  );
};

const TaxId = ({ taxId }: { taxId: Maybe<string> }) => {
  const [visible, setVisible] = useState(false);

  const id = visible ? taxId : `•••••${taxId?.slice(-4)}`;

  if (!taxId) return null;

  return (
    <Group wrap="nowrap" gap={5} pb={2}>
      <Text component="span" pt={2}>
        {id}
      </Text>
      <IconButton onClick={() => setVisible((e) => !e)}>
        <Icon icon={visible ? 'EyeSlashIcon' : 'EyeIcon'} size={18} />
      </IconButton>
    </Group>
  );
};

type Ownership = {
  id: any;
  listing: {
    id: any;
    name: string;
    address: string | undefined;
  };
  split: number | undefined;
};

const ListingPeriod = ({
  memberships: rows,
}: { memberships: Owner['currentPeriodMemberships'] }) => {
  const setListing = useQueryParamSet('listing', StringParam);

  return (
    <DrawerCollapsableTable
      title="Current Ownerships"
      rightSection={null}
      rowData={rows}
      columns={[
        {
          header: 'Owner',
          accessorKey: 'id',
          Cell: ({ row }) => {
            const member = row.original;

            return (
              <Badge
                key={member.id}
                leftIcon={
                  <Center>
                    <Icon
                      icon={'HomeIcon'}
                      size={14}
                      color={(theme) => theme.colors[theme.primaryColor][5]}
                    />
                  </Center>
                }
              >
                {member.listingName}
              </Badge>
            );
          },
        },
        {
          header: 'Split',
          accessorKey: 'split',
          maxSize: 150,
          mantineTableBodyCellProps: {
            align: 'right',
          },
          Cell: ({ row }) => {
            const data = row.original;
            return formatPercentage((data.split || 0) / 100);
          },
        },
      ]}
      emptyRowsFallback={() => (
        <Center>
          <Text size="sm" color="gray">
            No listing
          </Text>
        </Center>
      )}
      onRowClick={{
        handler: (row) => setListing(row.original.listingId, 'push'),
      }}
    />
  );
};

const ListingOwnerships = ({
  ownerships,
  ownerId,
}: { ownerships: Ownership[]; ownerId: string }) => {
  const columns = useMemo<MRT_ColumnDef<Owner['ownerships'][number]>[]>(
    () => [
      {
        header: 'Listing',
        accessorKey: 'listing.name',
        Cell: ({ row }) => (
          <Box>
            <Text component="span" display="block" size="sm">
              {row.original.listing.name}
            </Text>
            <Text component="span" display="block" size="xs" color="gray">
              {row.original.listing.address}
            </Text>
          </Box>
        ),
      },
      {
        header: 'Split',
        accessorKey: 'split',
        mantineTableBodyCellProps: {
          align: 'right',
        },
        size: 100,
        Cell: ({ row }) => {
          const split = row.original.split;
          const initialValue = typeof split === 'number' ? split : '';
          const [value, setValue] = useDebouncedState<number | string>(
            initialValue,
            500
          );
          const ownershipId = row.original.id;

          useEffect(() => {
            setValue(initialValue);
          }, [initialValue]);
          const { track } = useTracking();

          const { mutate, loading } = useMutation(
            (q, args: { ownershipId: string; split: number | null }) => {
              return q.updateListingOwner({
                pk_columns: {
                  id: args.ownershipId,
                },
                _set: {
                  split: args.split,
                },
              })?.id;
            }
          );

          return (
            <Box
              sx={(theme) => ({
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'flex-end',
                width: '100%',
                gap: theme.spacing.xs,
              })}
            >
              <InputPercentage
                height={20}
                maw={90}
                value={value}
                onChange={setValue}
                loadingMutation={loading}
                placeholder="AUTO"
                decimalScale={0}
                hideControls
                onBlur={(event) => {
                  const newValue = event.target.value
                    ? parseFloat(event.target.value)
                    : '';

                  mutate({
                    args: {
                      ownershipId,
                      split: typeof newValue === 'number' ? newValue : null,
                    },
                  })
                    .then(() => {
                      setValue(newValue);
                    })
                    .then(() => {
                      track('ownership_updated', {
                        listingId: row.original.listing.id,
                        ownerId: ownerId,
                        ownershipId: ownershipId,
                      });
                    });
                }}
              />
            </Box>
          );
        },
      },
    ],
    []
  );

  return (
    <DrawerCollapsableTable
      title="Listing ownerships"
      rightSection={<AddEntityToOwner ownerId={ownerId} type="listing" />}
      rowData={ownerships}
      columns={columns}
      emptyRowsFallback={() => (
        <Center>
          <Text size="sm" color="gray">
            No listing ownerships
          </Text>
        </Center>
      )}
      rowMenu={{
        menuItems: ({ row }) => {
          const { copy, copied } = useClipboard();
          const { track } = useTracking();

          const ownershipId = row.original.id;

          const { mutate, loading } = useMutation(
            (q, args: { ownershipId: string }) => {
              return q.deleteListingOwner({
                id: args.ownershipId,
              })?.id;
            },
            {
              invalidateQueryKeys: ['owners'],
            }
          );

          useEffect(() => {
            if (copied)
              showSuccessNotification({
                message: 'Ownership ID copied to clipboard',
              });
          }, [copied]);

          return (
            <>
              <EllipsisMenuDangerItem
                loading={loading}
                onClick={() =>
                  mutate({
                    args: {
                      ownershipId,
                    },
                  }).then(() => {
                    track('ownership_deleted', {
                      listingId: row.original.listing.id,
                      ownerId: ownerId,
                      ownershipId: ownershipId,
                    });
                  })
                }
              >
                Remove ownership
              </EllipsisMenuDangerItem>

              <HiddenFeatureIndicator permission="super-admin">
                <EllipsisMenuDivider />
                <EllipsisMenuItem
                  customIcon={<CopyIcon size={18} />}
                  onClick={() => copy(ownershipId)}
                >
                  Copy ownership ID
                </EllipsisMenuItem>
              </HiddenFeatureIndicator>
            </>
          );
        },
      }}
    />
  );
};

const UserAccesses = ({
  userAccesses,
  ownerId,
}: { userAccesses: Owner['userAccesses']; ownerId: string }) => {
  const { open } = useOwnerUserDetailDrawer();

  const columns = useMemo<MRT_ColumnDef<Owner['userAccesses'][number]>[]>(
    () => [
      {
        header: 'Listing',
        accessorKey: 'name',
        Cell: ({ row }) => (
          <Box>
            <Text component="span" display="block" size="sm">
              {row.original.name}
            </Text>
            <Text component="span" display="block" size="xs" color="gray">
              {row.original.email}
            </Text>
          </Box>
        ),
      },
      {
        header: 'Role',
        accessorKey: 'role',
        mantineTableBodyCellProps: {
          align: 'right',
        },
        mantineTableHeadCellProps: {
          align: 'right',
        },
        Cell: ({ row }) => {
          const [newRole, setNewRole] = useState<string>();

          const { mutate, loading } = useMutation(
            (
              q,
              args: {
                userAccessId: string;
                role: gqlV2.owner_user_access_role_enum;
              }
            ) => {
              return q.updateOwnerUserAccesses({
                pk_columns: {
                  id: args.userAccessId,
                },
                _set: {
                  role: args.role,
                },
              })?.role;
            }
          );

          const options = ensure<
            SelectItem<gqlV2.owner_user_access_role_enum>[]
          >([
            {
              label: 'Owner',
              value: 'admin',
              icon: <Edit3Icon size={16} />,
            },
            {
              label: 'Viewer',
              value: 'viewer',
              icon: <EyeIcon size={16} />,
            },
          ]);

          const value = newRole || row.original.role;
          const formatted = options.find((o) => o.value === value) || null;

          return (
            <Box w={130}>
              <InputSelect
                data={{
                  options,
                }}
                type="single"
                setValue={(value) => {
                  if (!value?.value) return;

                  mutate({
                    args: {
                      role: value.value as gqlV2.owner_user_access_role_enum,
                      userAccessId: row.original.id,
                    },
                  }).then((role) => setNewRole(role));
                }}
                value={formatted || null}
                inputProps={{
                  loadingMutation: loading,
                }}
                dropdownProps={{
                  withinPortal: true,
                  position: 'bottom-end',
                  width: 200,
                }}
              />
            </Box>
          );
        },
      },
    ],
    []
  );

  return (
    <DrawerCollapsableTable
      title="User Access"
      rightSection={<AddEntityToOwner ownerId={ownerId} type="user" />}
      rowData={userAccesses}
      columns={columns}
      onRowClick={{
        handler: (row) => open(row.original.userId, 'overview', 'push'),
      }}
      emptyRowsFallback={() => (
        <Center>
          <Text size="sm" color="gray">
            No users with access found
          </Text>
        </Center>
      )}
      rowMenu={{
        menuItems: ({ row }) => {
          const { copy, copied } = useClipboard();

          const accessId = row.original.id;

          const { mutate, loading } = useMutation(
            (q, args: { accessId: string }) => {
              return q.deleteOwnerUserAccess({
                id: args.accessId,
              })?.id;
            },
            {
              invalidateQueryKeys: ['owners'],
            }
          );

          useEffect(() => {
            if (copied)
              showSuccessNotification({
                message: 'Access ID copied to clipboard',
              });
          }, [copied]);

          return (
            <>
              <EllipsisMenuDangerItem
                loading={loading}
                onClick={() =>
                  mutate({
                    args: {
                      accessId,
                    },
                  })
                }
              >
                Remove user access
              </EllipsisMenuDangerItem>

              <HiddenFeatureIndicator permission="super-admin">
                <EllipsisMenuDivider />
                <EllipsisMenuItem
                  customIcon={<CopyIcon size={18} />}
                  onClick={() => copy(accessId)}
                >
                  Copy user access ID
                </EllipsisMenuItem>
              </HiddenFeatureIndicator>
            </>
          );
        },
      }}
    />
  );
};

const OwnerStatementsTable = ({
  statements,
  ownerId,
  hideAddButton,
}: {
  statements: Owner['ownerStatements'];
  ownerId: string;
  hideAddButton?: boolean;
}) => {
  const goto = useNavigate();

  return (
    <DrawerCollapsableTable
      title="Owner statements"
      rightSection={
        !hideAddButton && (
          <AddEntityToOwner ownerId={ownerId} type="statement" />
        )
      }
      rowData={statements}
      columns={[
        {
          header: 'Owner',
          accessorKey: 'id',
          Cell: ({ row }) => {
            const statement = row.original;

            return (
              <Box>
                <Text component="span" display="block" size="sm">
                  {utc(statement.startAt).format('MMM YYYY')}
                </Text>
                {statement.listing.name && (
                  <Text component="span" display="block" size="xs" color="gray">
                    {statement.listing.name}
                  </Text>
                )}
              </Box>
            );
          },
        },
        {
          header: 'Status',
          accessorKey: 'status',
          maxSize: 150,
          mantineTableBodyCellProps: {
            align: 'right',
          },
          Cell: ({ row }) => {
            const statement = row.original;

            return (
              <Group wrap="nowrap">
                <ListingOwnerBadge
                  role={statement.statementOwner.role}
                  name={statement.statementOwner.name}
                  variant="icon"
                />
                <StatementStatusBadge status={statement.status} />
              </Group>
            );
          },
        },
      ]}
      onRowClick={{
        handler: (row) =>
          goto(
            `/statement/${row.original.listing.id}?date=${utc(
              row.original.startAt
            ).yyyymmdd()}&statementOwner=${
              row.original.statementOwner.id
            }&owner=${ownerId}`
          ),
      }}
      emptyRowsFallback={() => (
        <Center>
          <Text size="sm" color="gray">
            No owner statements
          </Text>
        </Center>
      )}
    />
  );
};

const TaxStatementsTable = ({
  ownerId,
  ownerStatements,
}: {
  ownerId: string;
  ownerStatements: Owner['ownerStatements'];
}) => {
  const [teamId] = useTeamId();

  const { isLoading: loadingYears, data: years } = useQuery(
    (q, args) => {
      const taxStatements = q
        .taxStatements({
          where: {
            ownerId: { _eq: args.ownerId },
            tenantId: { _eq: args.teamId },
          },
        })
        .map((x) => x.year);

      const years = Array.from(
        new Set([
          ...taxStatements.filter(hasValue),
          ...args.ownerStatementYears,
        ])
      );

      return sortBy(years, undefined, 'desc');
    },
    {
      skip: !ownerId || !teamId,
      variables: {
        ownerId,
        ownerStatementYears: ownerStatements
          .filter((x) => x.status === 'posted' || x.status === 'published')
          .map((statement) => utc(statement.startAt).year()),
        teamId,
      },
    }
  );

  const { collectionId, loading: loadingCollectionId } =
    useTaxAccountCollectionQuery();

  const { isLoading: loadingTaxStatements, data: taxStatements } = useQuery(
    (q, args) => {
      const owner = q.owner({
        id: args.ownerId,
      });

      const taxStatements = (args.years || []).map((year) => {
        return getTaxStatement({
          owner,
          year,
          teamId: args.teamId,
          taxAccountCollectionId: args.collectionId,
        });
      });

      return taxStatements;
    },
    {
      skip: !ownerId || !teamId || !years?.length || !collectionId,
      variables: {
        ownerId,
        years,
        teamId,
        collectionId,
      },
    }
  );

  const loading = loadingCollectionId || loadingTaxStatements || loadingYears;

  return (
    <DrawerCollapsableTable
      title="Tax statements"
      rightSection={null}
      loading={loading}
      rowData={taxStatements || []}
      columns={[
        {
          header: 'Year',
          accessorKey: 'year',
          maxSize: 50,
        },
        {
          header: 'Status',
          accessorKey: 'status',
          mantineTableBodyCellProps: {
            align: 'right',
          },
          Cell: ({ row }) => {
            return (
              <Group wrap="nowrap">
                <TaxStatementAmountCell {...row.original} />
                <TaxStatementStatusSelect
                  openFilingConfirmModal={() => {}}
                  statement={row.original}
                  disabled
                />
              </Group>
            );
          },
        },
      ]}
      emptyRowsFallback={() => (
        <Center>
          <Text size="sm" color="gray">
            No tax statements
          </Text>
        </Center>
      )}
    />
  );
};

const AddEntityToOwner = ({
  ownerId,
  type,
}: { ownerId: string; type: 'listing' | 'statement' | 'user' }) => {
  const [teamId] = useTeamId();
  const [dashboard] = useDashboard();
  const [search, setSearch] = useState('');
  const { id: meId } = useMe();
  const { GL } = useEnabledFeatures();

  const queryData = useInfiniteQuery(
    (
      q,
      { teamId, dashboard, search, ownerId, type, meId, GL },
      { limit, offset }
    ) => {
      if (type === 'user') {
        const where: gqlV2.user_bool_exp = {
          ...whereOwnerUsers({
            teamId,
            search,
          }),
          _not: {
            ownerAccesses: {
              owner: {
                id: {
                  _eq: ownerId,
                },
                tenantId: { _eq: teamId },
              },
            },
          },
        };

        const list = q
          .user({
            where,
            limit,
            offset,
            order_by: [{ firstName: 'asc_nulls_last' }],
          })
          .map<SelectItem>((user) => ({
            label: formatUserName(user),
            value: user.id,
          }));

        const aggregate = q.userAggregate({ where }).aggregate?.count() || 0;

        return {
          list,
          aggregate,
        };
      }

      if (type === 'statement') {
        const where: gqlV2.owner_statement_bool_exp = {
          tenantId: { _eq: teamId },
          listing: {
            ownerships: {
              role: {
                _eq: 'spectator',
              },
              ownerId: { _eq: ownerId },
            },
            _or: search
              ? [
                  { title: { _ilike: `%${search}%` } },
                  { name: { _ilike: `%${search}%` } },
                  { address: { _ilike: `%${search}%` } },
                ]
              : undefined,
          },
          _not: {
            owners: {
              newOwnerId: {
                _eq: ownerId,
              },
            },
          },
        };

        const list = q
          .ownerStatements({
            where,
            limit,
            offset,
            order_by: [{ startAt: 'desc' }],
          })
          .map<SelectItem>((statement) => ({
            label: `${getListingName(statement.listing!)} - ${utc(
              statement.startAt
            ).format('MMM YYYY')}`,
            value: statement.id,
          }));

        const aggregate =
          q.ownerStatementAggregate({ where }).aggregate?.count() || 0;

        return {
          list,
          aggregate,
        };
      }

      const where: gqlV2.listing_bool_exp = {
        ...whereListings({
          currentTeamId: teamId,
          dashboard,
          search,
          partnerTeamIds: [],
          meId,
          GL,
        }),
        _not: {
          ownerships: {
            newOwnerId: {
              _eq: ownerId,
            },
          },
        },
      };

      const list = q
        .listings({
          where,
          limit,
          offset,
          order_by: [
            {
              calculated_title: 'asc_nulls_last',
            },
          ],
        })
        .map<SelectItem>((listing) => ({
          label: getListingName(listing),
          value: listing.id,
        }));
      const aggregate = q.listingAggregate({ where }).aggregate?.count() || 0;

      return {
        list,
        aggregate,
      };
    },
    {
      skip: !teamId,
      queryKey: 'owners',
      variables: {
        teamId,
        search: search?.trim(),
        dashboard,
        ownerId,
        type,
        meId,
        GL,
      },
    }
  );

  const { track } = useTracking();

  const { mutate, loading } = useMutation(
    (
      q,
      args: {
        entityId: string;
        ownerId: string;
        type: 'listing' | 'statement' | 'user';
      }
    ) => {
      if (args.type === 'statement') {
        return q.insertOwnerStatementOwner({
          object: {
            statementId: args.entityId,
            newOwnerId: args.ownerId,
            role: 'spectator',
          },
        })?.id as string | undefined;
      }

      if (args.type === 'user') {
        return q.insertOwnerUserAccess({
          object: {
            userId: args.entityId,
            ownerId: args.ownerId,
          },
        })?.id as string | undefined;
      }

      return q.insertListingOwner({
        object: {
          listingId: args.entityId,
          newOwnerId: args.ownerId,
          role: 'owner',
        },
      })?.id as string | undefined;
    },
    {
      invalidateQueryKeys: ['owners'],
    }
  );

  return (
    <Select
      infiniteData={{ ...queryData, setSearch }}
      type="single"
      value={null}
      setValue={(value) => {
        if (!value) return;
        const entityId = value.value;
        mutate({ args: { entityId, ownerId, type } }).then((res) => {
          if (type === 'listing') {
            track('ownership_created', {
              listingId: entityId,
              ownerId: ownerId,
              ownershipId: res,
            });
          }
        });
      }}
      dropdownProps={{
        position: 'bottom-end',
        noOptionsText: 'No owner statements available',
      }}
    >
      {() => {
        return (
          <IconButton loading={loading}>
            <PlusIcon size={18} />
          </IconButton>
        );
      }}
    </Select>
  );
};
