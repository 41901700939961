import { gqlV2, useTeamId } from '@finalytic/data';
import { timestamptz_comparison_exp } from '@finalytic/graphql';
import { Maybe, day, hasValue } from '@finalytic/utils';
import { useMemo } from 'react';
import { useExpenseTableFilter } from './ExpenseTableFilter';

export function useWhereExpenses() {
  const [teamId] = useTeamId();
  const { filter } = useExpenseTableFilter();

  const listingIds = (filter.listingIds || []).filter(hasValue);
  const search = filter.search?.trim();

  return useMemo<gqlV2.transaction_bool_exp>(
    () =>
      whereExpenses({
        tenantId: teamId,
        date: filter.date,
        listingIds,
        search,
        type: filter.type,
      }),
    [teamId, filter.date, ...listingIds, search, filter.type]
  );
}

export const whereExpenses = ({
  tenantId,
  date,
  listingIds,
  search,
  type,
}: {
  tenantId: string;
  search?: Maybe<string>;
  type?: Maybe<string>;
  listingIds?: Maybe<string[]>;
  date?: Maybe<string>;
}): gqlV2.transaction_bool_exp => {
  const getDate = (): timestamptz_comparison_exp | undefined => {
    if (!date) return undefined;

    const [start, end] = date.split('...');

    if (!end)
      return {
        _gte: start,
        _lt: day(start).add(1, 'day').yyyymmdd(),
      };

    return {
      _gte: start,
      _lte: end,
    };
  };

  return {
    tenantId: { _eq: tenantId },
    type: type ? { _eq: type as any } : { _eq: 'expense' },
    date: getDate(),
    description: search ? { _ilike: `%${search}%` } : undefined,
    lines: listingIds?.length
      ? {
          listingId: {
            _in: listingIds,
          },
        }
      : undefined,
  };
};
