import { day } from '@finalytic/utils';
import { AnalyticsBrowser } from '@june-so/analytics-next';
import {
  GroupProps,
  IdentifyProps,
  TrackingEvent as LogTrackingEvent,
  convertKeysToSnakeCase,
  isTest,
} from '@vrplatform/log';
import { formatUserName } from '@vrplatform/ui-common';
import { usePostHog } from 'posthog-js/react';
import { useCallback } from 'react';
import { useGqtyClient, useMutation } from '../graphql';
import { captureSentryError } from './sentry';
import { useMe, useTeam } from './team';
import { useIntercom } from './useIntercom';

const june = AnalyticsBrowser.load({ writeKey: '7y5Pk4FXEinDywCO' });

export type Tracking = ReturnType<typeof useTracking>;
export type TrackingEvent = LogTrackingEvent;

export function useTracking() {
  const { trackEvent, update, shutdown } = useIntercom();
  const posthog = usePostHog();
  const [{ id: tenantId }] = useTeam();
  const {
    id: userId,
    email: userEmail,
    firstName,
    lastName,
    createdAt,
  } = useMe();
  const gqt = useGqtyClient();

  const { mutate } = useMutation(
    (
      q,
      args: {
        event: string;
        tenantId: string;
        userId: string;
        properties?: Record<string, any>;
      }
    ) =>
      q.insert_tracking_event_one({
        object: {
          name: args.event,
          tenantId: args.tenantId,
          userId: args.userId,
          props: args.properties,
          packageName: 'ui',
        },
      })?.id,
    {
      hideNotification: true,
    }
  );

  if (isTest(userId, tenantId, userEmail))
    return {
      track: async () => {},
      identify: () => {},
      group: () => {},
      page: () => {},
      reset: () => {},
      posthog,
    };

  return {
    track: useCallback(
      async (
        event: LogTrackingEvent,
        data: {
          userId?: string;
          tenantId?: string;
          userEmail?: string;
          [key: string]: string | number | boolean | null | undefined;
        }
      ) => {
        const meta = { userId, tenantId, userEmail, ...data };

        if (isTest(meta.userId, meta.tenantId, meta.userEmail)) return;

        if (gqt)
          await mutate({
            args: {
              event,
              tenantId: meta.tenantId,
              userId: meta.userId,
              properties: meta,
            },
          }).catch((err) => {
            captureSentryError(err);
          });
        else
          console.warn(
            `GQTY client not found. Tracking event ${event} will not work!`,
            meta
          );

        if (!posthog._isIdentified && meta.userId)
          posthog.identify(meta.userId);
        posthog?.capture(event, convertKeysToSnakeCase(meta));

        trackEvent?.(event, meta);
        await june
          .track(event, meta, {
            context: { groupId: meta.tenantId },
            userId: meta.userId,
          })
          .catch((err) => {
            captureSentryError(err);
          });
      },
      [posthog, june, userId, tenantId, userEmail, gqt]
    ),
    identify: useCallback(
      (userId: string, traits: IdentifyProps['traits']) => {
        posthog?.identify(userId, convertKeysToSnakeCase(traits));
        june.identify(userId, traits);
        update?.({
          user_id: userId,
          email: traits?.email || userEmail,
          created_at:
            traits?.createdAt || createdAt
              ? day(traits?.createdAt || createdAt).unix()
              : undefined,
          name: formatUserName(
            {
              firstName: traits?.firstName || firstName,
              lastName: traits?.lastName || lastName,
            },
            { lastNameFirst: false, showEmpty: true }
          ),
          last_request_at: day().unix(),
          last_seen_at: traits?.lastSeenAt
            ? day(traits?.lastSeenAt).unix()
            : day().unix(),
          avatar: traits?.avatar,
          // custom properties
          userType: traits?.role,
        });
      },
      [posthog, june]
    ),
    group: useCallback(
      (groupId: string, traits: GroupProps['traits'] = {}) => {
        // add teamId to traits
        if (!traits?.teamId) traits.teamId = groupId;

        const { name, ...meta } = traits || {};
        const cid = traits?.billingCustomerId || traits?.hyperlineCustomerId;

        posthog?.group('tenant', groupId, convertKeysToSnakeCase(meta));
        june.group(groupId, traits);
        update?.({
          user_id: userId,
          company: {
            company_id: groupId,
            name: traits?.name,
            created_at: traits?.createdAt
              ? new Date(traits.createdAt).getTime() / 1000
              : undefined,
            plan: traits?.plan,
            monthly_spend: traits?.mrr,
            // custom properties
            teamId: groupId,
            team: traits?.name,
            type: traits?.type,
            status: traits?.status,
            pms: traits?.pms,
            accountingSoftware: traits?.accountingSoftware,
            activeListings: traits?.activeListings,
            paymentMethodType: traits?.paymentMethodType,
            partnerName: traits?.partner || traits?.accountingPartner,
            billingPortalUrl: cid
              ? `https://billing.vrplatform.app/portal/${cid}`
              : undefined,
            billingStatus: traits?.billingSubscriptionStatus,
            billingPartner:
              traits?.billingPartner ||
              traits?.partner ||
              traits?.accountingPartner,
          },
        });
      },
      [posthog, june]
    ),
    page: useCallback(
      (
        category?: string,
        name?: string,
        data?: Record<string, string | number | boolean | null | undefined>
      ) => {
        posthog?.capture?.('$pageview', convertKeysToSnakeCase(data));
        june?.page(category, name, data);
        update?.({ user_id: userId, page_title: name });
      },
      [posthog, june]
    ),
    reset: useCallback(() => {
      posthog?.reset(true);
      june?.reset();
      shutdown?.();
    }, [posthog, june]),
    posthog,
  };
}
