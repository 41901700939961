import { day } from '@finalytic/utils';
import {
  Box,
  Group,
  Popover,
  SegmentedControl,
  useMantineColorScheme,
  useMantineTheme,
} from '@mantine/core';
import { DatePicker, MonthPicker } from '@mantine/dates';
import { useDisclosure } from '@mantine/hooks';
import { PropsWithChildren, useCallback, useMemo, useState } from 'react';
import { Divider } from '../Divider';
import { Button } from '../button';
import { FilterPill } from './FilterPill';

export type CalendarFilterDateType = [Date | null, Date | null];
export type CheckInOutType = 'all' | 'checkIn' | 'checkOut' | 'bookedAt';

type CheckInOutTypeHandler = (value: CheckInOutType) => void;

type DateInputProps = {
  dateType?: CheckInOutType;
  setDateType?: CheckInOutTypeHandler;
  filterValue?: CalendarFilterDateType;
  setFilterValue: (value: CalendarFilterDateType | undefined) => void;
};

type Props = {
  value: DateInputProps['filterValue'] | string;
  setValue: DateInputProps['setFilterValue'];
  dateType?: DateInputProps['dateType'];
  setDateType?: DateInputProps['setDateType'];
  clearable?: boolean;
  placeholder?: string;
};

const getFormatted = ([first, second]: [
  string | Date | null,
  string | Date | null,
]) => {
  if (first && second) {
    const value = [
      day(first).toDate(),
      day(second).toDate(),
    ] as CalendarFilterDateType;

    return {
      label: `${day(first).format('MMM DD')} - ${day(second).format(
        'MMM DD YYYY'
      )} `,
      value,
    };
  }
  if (first) {
    const value = [day(first).toDate(), null] as CalendarFilterDateType;
    return { label: `${day(first).format('MMM DD YYYY')}`, value };
  }

  return { label: '', value: undefined };
};

export const DateFilter = (props: Props) => {
  const { value: v, setValue, setDateType, clearable = true } = props;
  const [opened, handlers] = useDisclosure(false);

  const { value, label } = useMemo<{
    value: CalendarFilterDateType | undefined;
    label: string;
  }>(() => {
    if (Array.isArray(v)) return getFormatted(v);

    if (typeof v === 'string') {
      const [first, second] = v.split('...');

      return getFormatted([first, second]);
    }
    return { value: undefined, label: '' };
  }, [v]);

  return (
    <Popover
      position="bottom-start"
      shadow="md"
      withinPortal
      opened={opened}
      onClose={handlers.close}
      onOpen={handlers.open}
    >
      <Popover.Target>
        <Box>
          <FilterPill
            opened={opened}
            isDefault={!value}
            label={label || props.placeholder || 'Date'}
            onClick={handlers.toggle}
            resetFilter={
              !clearable
                ? undefined
                : () => {
                    setValue(undefined);
                    handlers.close();
                    if (setDateType) {
                      setDateType('all');
                    }
                  }
            }
          />
        </Box>
      </Popover.Target>
      <Popover.Dropdown p={0}>
        <Dropdown {...props} value={value} closeModal={handlers.close} />
      </Popover.Dropdown>
    </Popover>
  );
};

const Dropdown = ({
  value: initial,
  setValue: setInitial,
  dateType: dT,
  setDateType: setDT,
  closeModal,
}: Omit<Props, 'value'> & {
  value: CalendarFilterDateType | undefined;
  closeModal: () => void;
}) => {
  const theme = useMantineTheme();
  const [value, setValue] = useState<CalendarFilterDateType | undefined>(
    initial
  );
  const [dateType, setDateType] = useState(dT || 'all');
  const [isMonthPicker, setIsMonthPicker] = useState(false);

  const { colorScheme } = useMantineColorScheme();

  const borderColor = theme.colors.gray[colorScheme === 'dark' ? 8 : 3];

  const currentYear = day().year();

  const onSubmit = () => {
    const hasValue = value && (value[0] || value[1]);
    setInitial(hasValue ? value : undefined);
    if (hasValue && setDT) setDT(dateType);
    closeModal();
  };

  const activeElements = useActiveDateRange(value);

  const setCurrentYear = useCallback(() => {
    const lastYear = day();
    setValue([
      lastYear.startOf('year').toDate(),
      lastYear.endOf('year').toDate(),
    ]);
    setIsMonthPicker(true);
  }, [setValue, setIsMonthPicker]);

  const setLastYear = useCallback(() => {
    const lastYear = day().subtract(1, 'year');
    setValue([
      lastYear.startOf('year').toDate(),
      lastYear.endOf('year').toDate(),
    ]);
    setIsMonthPicker(true);
  }, [setValue, setIsMonthPicker]);

  const setLast12Months = useCallback(() => {
    const lastYear = day().subtract(1, 'year');
    setValue([lastYear.toDate(), day().toDate()]);
    setIsMonthPicker(false);
  }, [setValue, setIsMonthPicker]);

  const setLast30Days = useCallback(() => {
    const last30Days = day().subtract(30, 'days');
    setValue([last30Days.toDate(), day().toDate()]);
    setIsMonthPicker(false);
  }, [setValue, setIsMonthPicker]);

  const setLast7Days = useCallback(() => {
    const last7Days = day().subtract(7, 'days');
    setValue([last7Days.toDate(), day().toDate()]);
    setIsMonthPicker(false);
  }, [setValue, setIsMonthPicker]);

  return (
    <Box
      sx={{
        display: 'flex',
        flexWrap: 'nowrap',
      }}
    >
      <Box
        sx={{
          width: '175px',
          borderRight: '1px solid',
          borderColor,
          display: 'flex',
          flexDirection: 'column',
        }}
        p={5}
      >
        <OptionButton
          onClick={setLast7Days}
          isActive={activeElements.last7Days}
        >
          Last 7 days
        </OptionButton>
        <OptionButton
          onClick={setLast30Days}
          isActive={activeElements.last30Days}
        >
          Last 30 days
        </OptionButton>
        <OptionButton
          onClick={setLast12Months}
          isActive={activeElements.last12Months}
        >
          Last 12 months
        </OptionButton>
        <Divider my={5} mx={-5} />
        <OptionButton
          onClick={setCurrentYear}
          isActive={activeElements.currentYear}
        >
          {currentYear}
        </OptionButton>
        <OptionButton onClick={setLastYear} isActive={activeElements.lastYear}>
          {currentYear - 1}
        </OptionButton>
        <OptionButton onClick={() => setIsMonthPicker(false)}>
          Custom dates
        </OptionButton>
        <OptionButton onClick={() => setIsMonthPicker(true)}>
          Custom months
        </OptionButton>
      </Box>
      <Box>
        <Box
          p="sm"
          sx={{
            borderBottom: '1px solid',
            borderColor,
          }}
        >
          {isMonthPicker ? (
            <MonthPicker
              type="range"
              numberOfColumns={2}
              value={value || [null, null]}
              level="year"
              defaultDate={value?.[1] || value?.[0] || undefined} // date is for which page is currently active
              onChange={(v) => {
                setValue(v);
              }}
            />
          ) : (
            <DatePicker
              value={value || [null, null]}
              type="range"
              defaultDate={value?.[1] || value?.[0] || undefined} // date is for which page is currently active
              level="month"
              numberOfColumns={2}
              onChange={setValue}
            />
          )}
        </Box>
        <Group justify="flex-end" py="sm" px="sm">
          {setDT && (
            <SegmentedControl
              size="xs"
              radius="md"
              data={date_type_options}
              value={dateType}
              onChange={(value) => setDateType(value as CheckInOutType)}
              sx={{
                justifySelf: 'flex-start',
                marginRight: 'auto',
              }}
            />
          )}
          <Button size="sm" onClick={closeModal}>
            Cancel
          </Button>
          <Button size="sm" variant="primary" onClick={onSubmit}>
            Apply
          </Button>
        </Group>
      </Box>
    </Box>
  );
};

const date_type_options = [
  { label: 'Within', value: 'all' },
  { label: 'Check In', value: 'checkIn' },
  { label: 'Check Out', value: 'checkOut' },
  { label: 'Booked', value: 'bookedAt' },
  { label: 'Cancelled', value: 'cancelledAt' },
];

const OptionButton = ({
  children,
  onClick,
  isActive,
}: PropsWithChildren<{
  isActive?: boolean;
  onClick?: () => void;
}>) => {
  return (
    <Button
      onClick={onClick}
      variant="light"
      size="sm"
      sx={(theme) => ({
        paddingInline: 9,
        '.mantine-Button-inner': {
          textAlign: 'left',
          justifyContent: 'flex-start',
        },
        backgroundColor: isActive ? theme.colors.gray[2] : undefined,
      })}
    >
      {children}
    </Button>
  );
};

function useActiveDateRange(date: CalendarFilterDateType | undefined) {
  type ActiveElements = {
    last7Days: boolean;
    last30Days: boolean;
    last12Months: boolean;
    currentYear: boolean;
    lastYear: boolean;
  };

  return useMemo<ActiveElements>(() => {
    const activeElements: ActiveElements = {
      last7Days: false,
      last30Days: false,
      last12Months: false,
      currentYear: false,
      lastYear: false,
    };

    if (!date?.[0] || !date?.[1]) {
      return activeElements;
    }

    const start = day(date[0]);
    const end = day(date[1]);

    const isEndToday = end.isSame(day(), 'day');

    if (start.isSame(day().subtract(7, 'days'), 'day') && isEndToday) {
      activeElements.last7Days = true;
      return activeElements;
    }

    if (start.isSame(day().subtract(30, 'days'), 'day') && isEndToday) {
      activeElements.last30Days = true;
      return activeElements;
    }

    if (start.isSame(day().subtract(1, 'year'), 'day') && isEndToday) {
      activeElements.last12Months = true;
      return activeElements;
    }

    if (
      start.isSame(day().startOf('year'), 'day') &&
      end.isSame(day().endOf('year'), 'day')
    ) {
      activeElements.currentYear = true;
      return activeElements;
    }

    if (
      start.isSame(day().subtract(1, 'year').startOf('year'), 'day') &&
      end.isSame(day().subtract(1, 'year').endOf('year'), 'day')
    ) {
      activeElements.lastYear = true;
      return activeElements;
    }

    return activeElements;
  }, [date]);
}
