import { Button } from '@finalytic/components';
import { useTeamId, useTrpcQuery } from '@finalytic/data';
import { Icon } from '@finalytic/icons';
import { LoadingIndicator } from '@finalytic/ui';
import { day, formatCurrency } from '@finalytic/utils';
import { PaymentMethod } from '@hyperline/react-components';
import {
  Box,
  Center,
  Divider,
  Skeleton,
  Stack,
  Text,
  rem,
} from '@mantine/core';
import { useState } from 'react';

const CONTAINER_WIDTH = 500;

export const OnboardingPricingRoute = () => {
  const [loadingRedirect, setLoadingRedirect] = useState(false);

  if (loadingRedirect) {
    return (
      <Center
        mih={'40dvh'}
        sx={{
          flexDirection: 'column',
        }}
      >
        <LoadingIndicator size="md" variant="dots" />
        <Text color="neutral" fw={500} size="md" mt="lg">
          Awaiting Confirmation
        </Text>
        <Text color="gray" size="sm" mt="xs">
          We are confirming your payment method. This may take a few seconds.
        </Text>
      </Center>
    );
  }

  return (
    <>
      <PlanDetail />
      <Divider maw={CONTAINER_WIDTH} mx="auto" my="md" />
      <PaymentDetail setRedirectLoading={() => setLoadingRedirect(true)} />
    </>
  );
};

const PlanDetail = () => {
  return (
    <Box maw={CONTAINER_WIDTH} mx="auto" mt="xl" mb={rem(50)}>
      <Text size="lg" ta="center" fw={500}>
        Today's Charge
      </Text>
      <Text size={rem(50)} ta="center" fw={400}>
        {formatCurrency(0, 'USD')}
      </Text>
      <Text size="sm" color="dark" ta="center" opacity={0.6}>
        (includes taxes)
      </Text>
      <Text ta="center" mt="lg" maw={CONTAINER_WIDTH / 1.7} mx="auto">
        Then a monthly charge from $12.50/listing from{' '}
        {day().add(2, 'weeks').format('MMMM DD, YYYY')}.
      </Text>
    </Box>
  );
};

const PaymentDetail = ({
  setRedirectLoading,
}: { setRedirectLoading: () => void }) => {
  const [teamId] = useTeamId();

  const { loading, data, refetch } = useTrpcQuery(
    'getHyperlineTrialComponentToken',
    {
      tenantId: teamId,
    }
  );

  return (
    <>
      <Box maw={CONTAINER_WIDTH} mx="auto">
        {loading ? (
          <Stack>
            <Skeleton height={50} />
            <Skeleton height={50} />
            <Skeleton height={50} />
          </Stack>
        ) : !data?.token ? (
          <Stack pt="xl">
            <Icon icon="AlertTriangleIcon" size={32} color="red" />

            <Text c="gray" ta="center" component="p" size="sm" mt={0}>
              There was an error loading the payment form.
            </Text>
            <Button
              variant="primary"
              sx={{
                width: '100%',
              }}
              onClick={refetch}
            >
              Retry
            </Button>
          </Stack>
        ) : (
          <PaymentMethod
            className="payment-method-iframe"
            options={{
              token: data.token,
              mode: data.isProduction ? 'production' : 'sandbox',
              locale: 'en',
            }}
            onPaymentMethodCreated={() => {
              console.log('Payment method created');
              setRedirectLoading();
              return {};
            }}
          />
        )}
      </Box>
    </>
  );
};
