import { Intercom } from '@finalytic/data';
import {
  type ExtendedCustomColors,
  RootThemeProvider,
  V2Provider,
} from '@finalytic/ui';
import { hasValue } from '@finalytic/utils';
import * as Sentry from '@sentry/react';
import posthog from 'posthog-js';
import { PostHogProvider } from 'posthog-js/react';
import { type ReactNode } from 'react';
import { ErrorBoundary } from '../components';
import { ReloadPrompt } from './ReloadPrompt';

const isLocalhost =
  window.location.host.includes('localhost') ||
  window.location.host.includes('127.0.0.1');
const isStaging = window.location.hostname === 'staging.portal.vrplatform.app';
const isProduction = window.location.hostname === 'portal.vrplatform.app';

if (!isLocalhost) {
  // POSTHOG
  posthog.init('phc_xIipUnhPJJfmkSZGObf95hIkwOcfT4bU5msyu7jZy8H', {
    api_host: 'https://pineapple.vrplatform.app',
  });

  // INTERCOM
  Intercom({
    custom_launcher_selector: '#intercom-widget',
    alignment: 'right',
    horizontal_padding: 40,
    vertical_padding: 40,
  });

  // SENTRY
  Sentry.init({
    dsn: 'https://4ba9b6d2701d4a889e6f323c050a4a46@o515442.ingest.sentry.io/5624987',
    // This sets the sample rate to be 10%. You may want this to be 100% while
    // in development and sample at a lower rate in production
    replaysSessionSampleRate: 1.0,
    // If the entire session is not sampled, use the below sample rate to sample
    // sessions when an error occurs.
    replaysOnErrorSampleRate: 1.0,
    environment: import.meta.env.DEV
      ? 'development'
      : isProduction
        ? 'production'
        : 'staging',
    tracesSampleRate: 0.1,
    tunnel: 'https://pomegranate.vrplatform.app',
    integrations: [
      new posthog.SentryIntegration(posthog, 'finalytic', 5624987),
      Sentry.browserTracingIntegration(),
      Sentry.replayIntegration({
        maskAllInputs: false,
        maskAllText: false,
        blockAllMedia: false,
        networkDetailAllowUrls: [
          'https://finalytic.hasura.app',
          'https://graphql.vrp.rocks',
          'https://clerk.vrplatform.app/v1/client/.*',
          'https://cdn.segment.com/v1/projects.*',
        ],
        networkCaptureBodies: true,
        networkResponseHeaders: ['X-Request-Id'],
        networkRequestHeaders: ['Finalytic-Platform'],
      }),
      isStaging
        ? Sentry.feedbackIntegration({
            colorScheme: 'light',
            showEmail: false,
            showName: false,
            showBranding: false,
            autoInject: false,
            themeLight: {
              submitBackground: '#025FCA',
              submitBackgroundHover: '#024797',
              submitBorder: '#025FCA',
              submitOutlineFocus: '#025FCA',
            },
          })
        : undefined,
    ].filter(hasValue),
  });
}

export const RootWrapper = ({
  children,
  themePrimaryColor,
}: {
  children: ReactNode;
  themePrimaryColor: ExtendedCustomColors;
}) => (
  <PostHogProvider client={posthog}>
    <ErrorBoundary>
      <V2Provider>
        <RootThemeProvider themePrimaryColor={themePrimaryColor}>
          {children}
          <ReloadPrompt />
        </RootThemeProvider>
      </V2Provider>
    </ErrorBoundary>
  </PostHogProvider>
);
