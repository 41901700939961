import { tenant_bool_exp, uuid_comparison_exp } from '@finalytic/graphql';
import { Maybe, ensure } from '@finalytic/utils';
import { z } from 'zod';
import { Dashboard } from '../types';

export const tenantsFilterInput = {
  search: z.string().nullish(),
  tenantId: z.string().nullish(),
  billingPartnerId: z.string().nullish(),
  billingSubscriptionStatus: z
    .enum(['active', 'canceled', 'pending', 'missing'])
    .nullish(),
  status: z.enum(['active', 'inactive', 'onboarding']).nullish(),
};

const tenantsFilter = z.object(tenantsFilterInput);

export type TenantsFilterParams = z.infer<typeof tenantsFilter>;

type BaseWhere = {
  partnerId: string;
  dashboard: Dashboard;
};

export function whereTenants({
  search: s,
  partnerId,
  status,
  dashboard,
  billingPartnerId,
  billingSubscriptionStatus,
  tenantId,
}: BaseWhere & TenantsFilterParams) {
  const isOverviewDashboard = dashboard === 'overview';
  const search = s?.trim();
  const searchQuery = search
    ? ensure<tenant_bool_exp[]>([
        {
          name: { _ilike: `%${search}%` },
        },
        {
          companyName: { _ilike: `%${search}%` },
        },
      ])
    : undefined;

  const billingPartnerFilter = (): Maybe<uuid_comparison_exp> => {
    if (!billingPartnerId) return undefined;

    if (!isOverviewDashboard && billingPartnerId === partnerId) {
      return { _is_null: true };
    }

    return { _eq: billingPartnerId };
  };

  return ensure<tenant_bool_exp>({
    id: tenantId ? { _eq: tenantId } : undefined,
    partnerId: isOverviewDashboard ? undefined : { _eq: partnerId },
    billingPartnerId: billingPartnerFilter(),
    status: status !== 'onboarding' ? { _eq: status } : undefined,
    billingSubscriptionStatus: billingSubscriptionStatus
      ? billingSubscriptionStatus === 'missing'
        ? { _is_null: true }
        : { _eq: billingSubscriptionStatus }
      : undefined,
    isOnboarding:
      status === 'onboarding' || status === 'active'
        ? { _eq: status === 'onboarding' }
        : undefined,
    type: { _nin: ['partner', 'admin'] },
    _or: searchQuery,
  });
}
