import { action, change } from '@finalytic/graphql';
import { day, toTitleCase } from '@finalytic/utils';
import { getActionMessage } from '@vrplatform/ui-common';
import { WorkflowAction } from './_types';

export const getWorkflowActionRow = (
  action: action,
  overwrites: { message: string; pattern: string }[]
): WorkflowAction => {
  const links = action.sourceLinks();

  return {
    createdAt: action.createdAt,
    id: action.id,
    jobPlanId: action.jobPlanId,
    jobId: action.jobId,
    status: action.status,
    message: getActionMessage(
      action.title || toTitleCase(action?.status || ''),
      overwrites
    ),
    type: toTitleCase(action.schema?.uniqueRef || ''),
    uniqueRef: action.uniqueRef,
    automationId: action.automationId || action.jobPlan?.automationId || '',
    inputJson: action.inputJson() || {},
    outputJson: action.outputJson() || {},
    date: action.job?.params()?.title || action.jobPlan?.params()?.title || '',
    hasExternalLinks: !!action.schema?.hasExternalLinks,
    links: {
      paymentIds: links
        .map((link) => ({
          id: link.source?.payment?.id,
          title: link.source?.payment?.uniqueRef,
        }))
        .filter((x) => !!x.id),
      reservationIds: links
        .map((link) => ({
          id: link.source?.reservation?.id,
          title: link.source?.reservation?.guestName,
        }))
        .filter((x) => !!x.id),
    },
  };
};

export const getWorkflowChangeRow = (
  change: change,
  overwrites: { message: string; pattern: string }[]
): WorkflowAction => {
  const source = change.source;

  return {
    createdAt: change.createdAt,
    id: change.id,
    jobPlanId: '',
    jobId: '',
    status: change.status,
    message: getActionMessage(change.message || '', overwrites),
    type: toTitleCase(change.entityType),
    date: day(change.message).isValid() ? day(change.message).yyyymmdd() : '',
    uniqueRef: change.entityUniqueRef,
    automationId: change.automationId || '',
    inputJson: undefined,
    outputJson: undefined,
    hasExternalLinks: false,
    links: {
      paymentIds: [
        {
          id: source?.payment?.id,
          title: source?.payment?.uniqueRef,
        },
      ].filter((x) => !!x.id),
      reservationIds: [
        {
          id: source?.reservation?.id,
          title: source?.reservation?.guestName,
        },
      ].filter((x) => !!x.id),
    },
  };
};
