import { useInfiniteQuery } from '@finalytic/data';
import { order_by, transaction } from '@finalytic/graphql';
import { MRT_SortingState } from '@finalytic/table';
import { sum } from '@finalytic/utils';
import { getListingName } from '@vrplatform/ui-common';
import { useWhereExpenses } from './useWhereExpenses';

type Params = {
  sorting: MRT_SortingState;
};

export type ExpenseRow = NonNullable<
  ReturnType<typeof useExpenseTableQuery>['data']
>['pages'][number]['list'][number];

export const getExpense = (expense: transaction) => {
  const lines = expense
    .lines({
      order_by: [{ createdAt: 'asc_nulls_last' }],
    })
    .map((line) => ({
      id: line.id,
      debitAccountId: line.debitAccountId,
      centTotal: line.centTotal ?? 0,
      description: line.description || '',
      taxRateId: line.taxRateId || null,
      taxBehavior: line.taxBehavior || null,
      markup: line
        .markups({ order_by: [{ updatedAt: 'desc_nulls_last' }] })
        .map((markup) => ({
          id: markup?.id,
          centTotal: markup?.centTotal,
          rate: markup?.rate,
          taxRateId: markup?.taxRateId || null,
          taxBehavior: markup?.taxBehavior || null,
        }))[0],
      listing: {
        id: line.listing?.id,
        name: getListingName(line.listing) || 'No name',
      },
      reservation: {
        id: line.reservation?.id,
        confirmationCode: line.reservation?.confirmationCode,
        guestName: line.reservation?.guestName,
        checkIn: line.reservation?.checkIn,
        checkOut: line.reservation?.checkOut,
      },
    }));

  return {
    id: expense.id,
    date: expense.date,
    status:
      expense.status === 'active' ? ('Paid' as const) : ('Unpaid' as const),
    bankAccount: {
      id: expense.creditAccountId,
      title: expense.account?.title,
    },
    description: expense.description,
    type: expense.type,
    centTotal: sum(lines.map((line) => line.centTotal)),
    currency: expense.currency!,
    lines,
  };
};

export const useExpenseTableQuery = ({ sorting }: Params) => {
  const where = useWhereExpenses();

  return useInfiniteQuery(
    (q, { where }, { limit, offset }) => {
      const aggregate =
        q.transactionAggregate({ where }).aggregate?.count() || 0;

      const order_by = sorting.map((sort) => {
        const sortId = sort.id as 'date' | 'description';
        const order: order_by = sort.desc
          ? 'desc_nulls_last'
          : 'asc_nulls_last';

        return {
          [sortId]: order,
        };
      });

      const list = q
        .transactions({
          where,
          limit,
          offset,
          order_by,
        })
        .map(getExpense);

      return {
        list,
        aggregate,
      };
    },
    {
      queryKey: 'expenses',
      variables: {
        where,
        sorting,
      },
    }
  );
};
