import { Badge } from '@finalytic/components';
import { syncStatus_enum } from '@finalytic/graphql';
import { ExtendedCustomColors } from '@finalytic/ui';
import { Maybe, toTitleCase } from '@finalytic/utils';

export const AutomationHistoryStatusBagde = ({
  status,
}: { status: Maybe<string> }) => {
  if (!status) return null;

  const badgeColors: Record<
    syncStatus_enum | (string & {}),
    ExtendedCustomColors
  > = {
    failed: 'red',
    started: 'yellow',
    completed: 'green',
    queued: 'yellow',
  };

  return (
    <Badge color={badgeColors[status] || 'gray'} size="lg">
      {toTitleCase(status)}
    </Badge>
  );
};
