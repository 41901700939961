import { listing_bool_exp } from '@finalytic/graphql';
import { ensure } from '@finalytic/utils';

export function whereListingIsActive() {
  // const earlyDate = day().subtract(30, 'days').format('YYYY-MM-DD');
  // const now = day().format('YYYY-MM-DD');

  return ensure<listing_bool_exp>({
    _or: [
      {
        status: { _is_null: true },
        pmsStatus: { _is_null: true },
      },
      {
        // TODO: VRP-4868 remove enabled/disabled
        pmsStatus: { _nin: ['disabled', 'inactive'] },
        status: { _is_null: true },
      },
      {
        // TODO: VRP-4868 remove enabled/disabled
        status: { _in: ['active', 'enabled'] },
      },
    ],
    // connections: {
    //   reservations: {
    //     _or: [
    //       {
    //         checkIn: { _gte: earlyDate, _lte: now },
    //       },
    //       {
    //         checkOut: { _gte: earlyDate, _lte: now },
    //       },
    //       {
    //         checkIn: { _lte: earlyDate },
    //         checkOut: { _gte: now },
    //       },
    //     ],
    //   },
    // },
  });
}
