import { Button } from '@finalytic/components';
import {
  useEnabledFeatures,
  useMe,
  useQuery,
  useTeamId,
} from '@finalytic/data';
import { Icon } from '@finalytic/icons';
import { StringParam, useAppName, useQueryParamSet } from '@finalytic/ui';
import { ensure } from '@finalytic/utils';
import {
  ActionIcon,
  Box,
  Center,
  Divider,
  Group,
  Text,
  Tooltip,
  Transition,
  rem,
  useMantineTheme,
} from '@mantine/core';
import { Notification } from '@mantine/core';
import { useSessionStorage } from '@mantine/hooks';
import { ComponentProps, useMemo } from 'react';
import { useLocation, useNavigate } from 'react-router';
import { useListingClassMappingInfo } from '../hooks';
import { IssueRow, getIssues } from '../queries';

type Props = {
  type: 'card' | 'affix';
};

function usePmErrorIssuesQuery() {
  const [teamId] = useTeamId();
  const { GL } = useEnabledFeatures();
  const { id: meId } = useMe();
  const listingClassMapping = useListingClassMappingInfo();

  return useQuery((q, args) => getIssues(q, args, { limit: 10, offset: 0 }), {
    queryKey: ['issues', 'issueMessageOverwrites'],
    keepPreviousData: true,
    refetchOnWindowFocus: true,
    skip: !teamId,
    variables: ensure<Parameters<typeof getIssues>[1]>({
      teamId,
      GL,
      onlyAggregate: false,
      search: undefined,
      level: 'error' as const,
      listingClassMapping,
      meId,
    }),
  });
}

export const PmErrorIssueNotification = ({ type }: Props) => {
  const { data, isInitialLoading } = usePmErrorIssuesQuery();
  const location = useLocation();
  const goto = useNavigate();
  const [teamId] = useTeamId();
  const [hideAffixBySession, setHideAffixBySession] = useSessionStorage({
    key: `${teamId}-pmun`, // pmun = pm urgent notification
    defaultValue: false,
  });

  if (type === 'card') {
    return (
      <Transition
        mounted={!!data?.list.length}
        transition="fade"
        duration={400}
        timingFunction="ease"
      >
        {(styles) => (
          <Box
            mb="md"
            style={styles}
            sx={(theme) => ({
              backgroundColor: theme.colors.red[0],
              border: `1px solid ${theme.colors.red[3]}`,
              padding: theme.spacing.md,
              borderRadius: theme.radius.md,
              display: 'flex',
              flexDirection: 'row',
              flexWrap: 'nowrap',
              alignItems: 'center',
              justifyContent: 'flex-start',
              gap: theme.spacing.md,
              boxShadow: theme.shadows.sm,
              [`@media (max-width: ${theme.breakpoints.xs})`]: {
                flexDirection: 'column',
                alignItems: 'stretch',
                textAlign: 'center',
              },
            })}
          >
            {data?.list.length === 1 ? (
              <SingleIssueContent {...data.list[0]} />
            ) : (
              <MultiIssueContent />
            )}
          </Box>
        )}
      </Transition>
    );
  }

  const hideAffix =
    hideAffixBySession ||
    !data?.list.length ||
    location.pathname.startsWith('/dashboard') ||
    location.pathname === '/';

  if (hideAffix) return null;

  return (
    <Notification
      radius={'xl'}
      icon={
        <Icon
          icon="AlertCircleIcon"
          size={18}
          color={(theme) => theme.colors.red[8]}
        />
      }
      loading={isInitialLoading}
      withCloseButton={false}
      styles={(theme) => ({
        root: {
          position: 'absolute',
          transition: 'left 0.3s ease-out',
          top: theme.spacing.sm,
          left: '50%',
          transform: 'translateX(-50%)',
          backgroundColor: theme.colors.red[0],
          border: `1px solid ${theme.colors.red[3]}`,
          zIndex: 10000,
          paddingRight: 0,
        },
        icon: {
          backgroundColor: 'transparent',
          marginRight: theme.spacing.xs,
        },
        description: {
          display: 'flex',
          flexDirection: 'row',
          flexWrap: 'nowrap',
          gap: theme.spacing.xs,
        },
      })}
    >
      {data.list.length === 1 ? (
        <NotificationContent {...data.list[0]} />
      ) : (
        <Group wrap="nowrap">
          <Text component="span" fw={500}>
            We've found multiple issues that are blocking your data processing.
          </Text>
          <Box
            component="button"
            onClick={() => goto('/dashboard/issues?level=error')}
            size="xs"
            variant="light"
            sx={(theme) => ({
              color: `${theme.colors.red[9]}!important`,
              background: 'transparent',
              border: 'none',
              cursor: 'pointer',
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              gap: rem(5),
              padding: 0,
              flexWrap: 'nowrap',
              ':active': {
                transform: 'translateY(1px)',
              },
            })}
            color="red"
          >
            <Text component="span" fw={500}>
              View issues
            </Text>
            <Icon
              icon="ArrowRightIcon"
              size={12}
              color={(theme) => theme.colors.red[9]}
              strokeWidth={2.5}
            />
          </Box>
        </Group>
      )}
      <Divider
        orientation="vertical"
        ml={rem(5)}
        sx={(theme) => ({
          borderColor: theme.colors.red[3],
        })}
      />
      <SnoozeUrgentIssueAffixButton
        setHideAffix={() => setHideAffixBySession(true)}
      />
    </Notification>
  );
};

const SnoozeUrgentIssueAffixButton = ({
  setHideAffix,
}: { setHideAffix: () => void }) => {
  return (
    <Tooltip label="Snooze" withinPortal withArrow zIndex={10001}>
      <ActionIcon onClick={setHideAffix} variant="transparent">
        <Icon
          icon="ClockZzzIcon"
          size={16}
          color={(theme) => theme.colors.red[9]}
        />
      </ActionIcon>
    </Tooltip>
  );
};

function useIssueContent({ message, type, itemName, id }: IssueRow) {
  const goto = useNavigate();
  const setListing = useQueryParamSet('listing', StringParam);

  return useMemo(() => {
    const options: Record<IssueRow['type'], ComponentProps<typeof Content>> = {
      'connection-error': {
        title: `Failed to fetch ${itemName}`,
        message,
        buttonLabel: 'Reconnect',
        onClick: () => {
          goto(`/connections?connectionDetailId=${id}`);
        },
      },
      'connection-version-outdated': {
        title: `Outdated connection for ${itemName}`,
        message,
        buttonLabel: 'Reconnect',
        onClick: () => {
          goto(`/connections?connectionDetailId=${id}`);
        },
      },
      'listing-class-missing': {
        title: `Missing class for ${itemName}`,
        message,
        buttonLabel: 'Assign class',
        onClick: () => {
          setListing(id);
        },
      },
      'listing-owner-missing': {
        title: `Missing owner for ${itemName}`,
        message,
        buttonLabel: 'Assign owner',
        onClick: () => {
          setListing(id);
        },
      },
      'owner-statement-reservation': {
        title: 'Incorrect reservations on owner statement',
        message,
        buttonLabel: 'Check statement',
        onClick: () => {
          goto('/statements');
        },
      },
    };

    return options[type];
  }, [message, type, itemName, id]);
}

const NotificationContent = (issue: IssueRow) => {
  const content = useIssueContent(issue);

  return (
    <Group wrap="nowrap">
      <Text size="sm" fw={500}>
        {content.title}
      </Text>

      <Box
        component="button"
        onClick={content.onClick}
        size="xs"
        variant="light"
        sx={(theme) => ({
          color: `${theme.colors.red[9]}!important`,
          background: 'transparent',
          border: 'none',
          cursor: 'pointer',
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          gap: rem(5),
          padding: 0,
          flexWrap: 'nowrap',
          ':active': {
            transform: 'translateY(1px)',
          },
        })}
        color="red"
      >
        <Text component="span" fw={500}>
          {content.buttonLabel}
        </Text>

        <Icon
          icon="ArrowRightIcon"
          size={12}
          color={(theme) => theme.colors.red[9]}
          strokeWidth={2.5}
        />
      </Box>
    </Group>
  );
};

const MultiIssueContent = () => {
  const { appName } = useAppName();

  const goto = useNavigate();

  const onClick = () => {
    goto('/dashboard/issues');
  };

  return (
    <Content
      title="Blocking issues"
      message={`We've found multiple issues that are blocking your data processing. Please review and fix them to continue using ${appName}.`}
      buttonLabel="View issues"
      onClick={onClick}
    />
  );
};

const SingleIssueContent = (issue: IssueRow) => {
  const content = useIssueContent(issue);

  return <Content {...content} />;
};

const Content = ({
  buttonLabel,
  message,
  onClick,
  title,
}: {
  title: string;
  message: string;
  buttonLabel: string;
  onClick: () => void;
}) => {
  const { colors } = useMantineTheme();
  const redColor = colors.red[8];

  return (
    <>
      <Center>
        <Icon icon="AlertCircleIcon" size={32} color={redColor} />
      </Center>
      <Box sx={{ flex: 1 }}>
        <Text size="lg" fw={500} c={colors.red[9]}>
          {title}
        </Text>
        <Text size="sm" c="neutral">
          {message}
        </Text>
      </Box>
      <Button onClick={onClick}>{buttonLabel}</Button>
    </>
  );
};
