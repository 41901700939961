import { RenameModal } from '@finalytic/components';
import { useMutation, useQuery, useTrpcMutation } from '@finalytic/data';
import {
  ConfirmModal,
  ConfirmResultType,
  HiddenFeatureIndicator,
} from '@finalytic/data-ui';
import { Icon } from '@finalytic/icons';
import {
  DeleteModal,
  EllipsisMenuDangerItem,
  EllipsisMenuDivider,
  EllipsisMenuItem,
  StringParam,
  showErrorNotification,
  showSuccessNotification,
  useQueryParamsSet,
} from '@finalytic/ui';
import { Maybe, toTitleCase } from '@finalytic/utils';
import { Box, Tooltip } from '@mantine/core';
import {
  connectionHiddenStatusDefault,
  deleteCheckConnection,
} from '@vrplatform/ui-common';
import { useExtractModal } from '../../modals';
import { ConnectionRow } from './useConnectionTableQuery';

export const ConnectionEllipsisMenuItems = ({
  data,
  openRenameModal,
  // openFetchModal: _,
  openDeleteModal,
  openUpdateModal,
}: {
  data: Maybe<ConnectionRow>;
  openRenameModal: () => void;
  // openFetchModal: () => void;
  openDeleteModal: () => void;
  openUpdateModal: () => void;
}) => {
  const id = data?.id;

  const setSearchParams = useQueryParamsSet({
    connectionId: StringParam,
    connectionDetailId: StringParam,
  });

  const { open: openExtractModal } = useExtractModal();

  const reconnect = () => setSearchParams({ connectionId: id });
  const connectionStatus = data?.connectionStatus || 'active';
  const isArchived = connectionHiddenStatusDefault.includes(
    connectionStatus.toLowerCase()
  );

  if (!id) return null;

  return (
    <>
      <EllipsisMenuItem
        customIcon={<Icon icon="Edit3Icon" size={16} />}
        onClick={openRenameModal}
      >
        Rename
      </EllipsisMenuItem>
      <EllipsisMenuItem
        customIcon={<Icon icon="LightningIcon" size={16} />}
        onClick={reconnect}
      >
        Reconnect
      </EllipsisMenuItem>
      <EllipsisMenuItem
        customIcon={<Icon icon="RefreshCwIcon" size={16} />}
        onClick={() => {
          openExtractModal({
            connectionId: id,
            extractType: undefined,
          });
        }}
      >
        Fetch
      </EllipsisMenuItem>
      <EllipsisMenuDivider />
      <EllipsisMenuItem
        onClick={openUpdateModal}
        customIcon={
          <Icon icon={isArchived ? 'RefreshCwIcon' : 'ArchiveIcon'} size={16} />
        }
      >
        {isArchived ? 'Enable' : 'Archive'}
      </EllipsisMenuItem>
      <DeleteEllipsisItem connectionId={id} openDeleteModal={openDeleteModal} />

      <HiddenFeatureIndicator permission="vrp-admin">
        <EllipsisMenuItem
          customIcon={
            <Icon
              icon="CopyIcon"
              size={16}
              color={(theme) => theme.colors.neutral[5]}
            />
          }
          disabled={!id}
          onClick={() => {
            navigator.clipboard.writeText(`${id}`).then(() =>
              showSuccessNotification({
                message: 'ID was added to your clipboard.',
              })
            );
          }}
        >
          Copy ID
        </EllipsisMenuItem>
      </HiddenFeatureIndicator>
    </>
  );
};

export const ConnectionEllipsisMenuModals = ({
  data,
  deleteModal,
  renameModal,
  updateModal,
  refresh,
}: {
  data: Maybe<ConnectionRow>;
  deleteModal: {
    opened: boolean;
    close: () => void;
  };
  updateModal: {
    opened: boolean;
    close: () => void;
  };
  renameModal: {
    opened: boolean;
    close: () => void;
  };

  refresh: () => void;
}) => {
  const id = data?.id;

  const { mutate: deleteConnection } = useTrpcMutation('deleteConnection', {
    successMessage: {
      message: 'Connection deleted successfully.',
      title: 'Success!',
    },
    notificationId: 'delete-connection',
  });

  const connectionStatus = data?.connectionStatus || 'active';
  const connectionName = data?.name;
  const isArchived = connectionHiddenStatusDefault.includes(
    connectionStatus.toLowerCase()
  );

  const handleConnectionDeletion = async (): Promise<ConfirmResultType> => {
    if (!id) return { isSuccess: false, message: 'Connection ID is missing.' };

    try {
      const res = await deleteConnection({
        foreignObjectId: id,
        tenantId: data?.tenant.id,
      });
      if (!res?.ok) throw new Error('Connection deletion failed.');
      if (refresh) refresh();
      return { isSuccess: true, autoCloseOnSuccess: true };
    } catch (error: any) {
      console.error(error);

      return {
        isSuccess: false,
        message: error?.message || 'Failed to delete connection.',
      };
    }
  };

  const { mutate: updateConnectionStatus } = useMutation(
    (
      q,
      args: { id: string; status: 'active' | 'archived'; tenantId: string }
    ) => {
      const connection = q.updateConnectionById({
        pk_columns: { id: args.id },
        _set: {
          status: args.status,
        },
      });

      if (args.status === 'archived') {
        q.updateAutomations({
          where: {
            tenantId: { _eq: args.tenantId },
            _or: [
              {
                leftConnectionId: { _eq: args.id },
              },
              {
                rightConnectionId: { _eq: args.id },
              },
            ],
          },
          _set: {
            status: 'inactive',
          },
        })?.affected_rows || 0;
      }

      return {
        ok: !!connection?.id,
      };
    },
    {
      successMessage: {
        id: `update-connection-${data?.id || ''}`,
        title: 'Success!',
        message: 'Successfully updated connection.',
      },
      invalidateQueryKeys: ['connections', 'scheduledEvents'],
    }
  );

  const handleConnectionArchive = async (): Promise<ConfirmResultType> => {
    if (!id) return { isSuccess: false, message: 'Connection ID is missing.' };
    if (!data.tenant?.id)
      return { isSuccess: false, message: 'Tenant ID is missing.' };

    try {
      const res = await updateConnectionStatus({
        args: {
          id,
          status: isArchived ? 'active' : 'archived',
          tenantId: data?.tenant.id,
        },
      });
      if (!res?.ok) throw new Error('Connection update failed.');
      if (refresh) refresh();
      return { isSuccess: true, autoCloseOnSuccess: true };
    } catch (error: any) {
      console.error(error);
      return {
        isSuccess: false,
        message: error?.message || 'Failed to update connection.',
      };
    }
  };

  const { mutate: updateConnectionTitle } = useMutation(
    (q, args: { id: string; title: string }) => {
      const connection = q.updateConnectionById({
        pk_columns: { id: args.id },
        _set: {
          name: args.title,
        },
      });

      return {
        ok: !!connection?.id,
      };
    },
    {
      successMessage: {
        id: `update-connection-${data?.id || ''}`,
        title: 'Success!',
        message: 'Successfully updated connection.',
      },
      invalidateQueryKeys: ['connections', 'scheduledEvents'],
    }
  );

  return (
    <>
      <DeleteModal
        opened={deleteModal.opened}
        onClose={deleteModal.close}
        onSubmit={handleConnectionDeletion}
        title={`Are you sure you want to delete this connection: ${connectionName}?`}
        subtitle="All connected data will be lost."
      />
      <ConfirmModal
        opened={updateModal.opened}
        onClose={updateModal.close}
        onSubmit={handleConnectionArchive}
        title={isArchived ? 'Enable connection' : 'Archive connection'}
        withCloseButton
        onSuccess={refresh}
        subtitle={
          isArchived
            ? `Would you like to enable ${connectionName || 'this connection'}?`
            : `Are you sure you want to archive ${
                connectionName || 'this connection'
              }? This will disable all automations connected to this connection.`
        }
        submitButtonTitle={isArchived ? 'Enable' : 'Archive'}
        icon={
          <Icon icon={isArchived ? 'RefreshCwIcon' : 'ArchiveIcon'} size={48} />
        }
        autoCloseOnSuccess
      />
      <RenameModal
        opened={renameModal.opened}
        onClose={renameModal.close}
        title="Edit Connection Name"
        inputTitle="Name"
        inputPlaceholder="Enter your connection name"
        defaultValue={connectionName}
        onSubmit={async (value) => {
          if (!id)
            return showErrorNotification({
              color: 'yellow',
              message: 'Connection ID is missing.',
              title: 'Missing input',
            });

          try {
            await updateConnectionTitle({
              args: {
                id,
                title: value,
              },
            });
            if (refresh) refresh();
            renameModal.close();
          } catch (error) {
            console.log(error);
          }
        }}
      />
    </>
  );
};

const DeleteEllipsisItem = ({
  connectionId,
  openDeleteModal,
}: { connectionId: string; openDeleteModal: () => void }) => {
  const { data: removeCheckData, isLoading: loading } = useQuery(
    (q, { connectionId }) => {
      const res = deleteCheckConnection(q, { connectionId }) || null;

      return res;
    },
    {
      variables: { connectionId },
      skip: !connectionId,
    }
  );

  const canDelete = removeCheckData?.canDelete;
  const canBeRemovedProps = Object.entries(removeCheckData || {}).filter(
    ([, value]) => typeof value === 'number' && value > 0
  ) as [string, number][];

  return (
    <Tooltip
      label={`Please remove connected ${toTitleCase(
        canBeRemovedProps?.[0]?.[0]
      )?.toLowerCase()} first.`}
      disabled={canDelete || canDelete === undefined}
    >
      <Box>
        <EllipsisMenuDangerItem
          disabled={!canDelete}
          loading={loading}
          onClick={openDeleteModal}
          data-testid="connection-delete"
        >
          Delete
        </EllipsisMenuDangerItem>
      </Box>
    </Tooltip>
  );
};
