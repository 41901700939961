import { CountBagde, IconButton } from '@finalytic/components';
import { useEnabledFeatures, useIntercom } from '@finalytic/data';
import { CommentQuestionIcon } from '@finalytic/icons';
import { Group, Text, rem, useMantineTheme } from '@mantine/core';
import { useState } from 'react';
import { useNavbarExpanded } from '../../../hooks';

export const NavbarSupportButton = () => {
  const { CUSTOM_EMAILS } = useEnabledFeatures();
  const { onHide, onShow, hide, show, unreadMessageCount } = useIntercom();
  const [isOpen, setIsOpen] = useState(false);
  onHide?.(() => setIsOpen(false));
  onShow?.(() => setIsOpen(true));

  const { colors } = useMantineTheme();

  const color = colors.neutral[0];

  const { isDesktopExpanded } = useNavbarExpanded();

  const toggle = () => (isOpen ? hide?.() : show?.());

  if (CUSTOM_EMAILS) {
    return (
      <IconButton
        onClick={toggle}
        icon="CommentQuestionIcon"
        size={18}
        color={(theme) => theme.colors.neutral[3]}
        count={unreadMessageCount}
        tooltip="Help & Support"
      />
    );
  }

  return (
    <Group
      onClick={toggle}
      gap="xs"
      mx="xs"
      px={'xs'}
      py={5}
      sx={(theme) => ({
        marginBottom: rem(5),
        cursor: 'pointer',
        transition: 'background-color 0.1s ease-in-out',
        borderRadius: theme.radius.md,
        ':active': {
          transform: 'translateY(1px)',
        },
        ':hover': {
          backgroundColor: theme.colors[theme.primaryColor][9] + 40,
        },
      })}
    >
      <CommentQuestionIcon size={18} color={color} />
      {isDesktopExpanded && (
        <>
          <Text c={color} component="span" size={'sm'}>
            Help & Support
          </Text>
          {unreadMessageCount ? (
            <CountBagde
              count={unreadMessageCount}
              size={'0.75rem'}
              sx={{
                marginLeft: 'auto',
              }}
            />
          ) : null}
        </>
      )}
    </Group>
  );
};
