import { LoadingIndicator } from '@finalytic/ui';
import { InputProps, Input as MInput } from '@mantine/core';
import { useDebouncedValue, useDidUpdate } from '@mantine/hooks';
import { ComponentPropsWithRef, forwardRef, useEffect, useState } from 'react';

type Props = {
  loadingQuery?: boolean;
  loadingMutation?: boolean;
  setValue?: (v: string) => void;
  debounce?: number;
} & Omit<InputProps, 'onChange'> &
  ComponentPropsWithRef<'input'>;

export const InputWrapper = MInput.Wrapper;
export const InputError = MInput.Error;

export const Input = forwardRef<HTMLInputElement, Props>(
  (
    {
      setValue,
      placeholder,
      loadingMutation,
      loadingQuery,
      debounce = 500,
      leftSection,
      value,
      ...props
    },
    ref
  ) => {
    const hasOnchange = !!props.onChange;

    const [input, setInput] = useState(value);

    const [delayed] = useDebouncedValue(input, debounce);

    useDidUpdate(() => {
      if (typeof delayed === 'string' && setValue) {
        setValue(delayed);
      }
    }, [delayed]);

    useEffect(() => {
      if (!hasOnchange) setInput(value);
    }, [value, hasOnchange]);

    return (
      <MInput
        ref={ref}
        value={hasOnchange ? value : input}
        onChange={(event) => setInput(event.target.value)}
        sx={(theme) => ({
          height: '100%',
          width: props.width,
          input: {
            '&:focus': {
              boxShadow: `0px 0px 0px 2px ${
                theme.colors[props.error ? 'red' : theme.primaryColor][4]
              }40`,
            },
          },
          ".mantine-Input-section[data-position='right']": {
            pointerEvents: 'initial',
          },
        })}
        radius="md"
        placeholder={placeholder}
        leftSection={
          loadingQuery ? <LoadingIndicator size="xs" /> : leftSection
        }
        rightSection={loadingMutation && <LoadingIndicator size="xs" />}
        {...props}
      />
    );
  }
);
