import { Maybe, sortBy, sum } from '@finalytic/utils';
import {
  Box,
  FloatingPosition,
  Group,
  Skeleton,
  Stack,
  Text,
  rem,
  useMantineTheme,
} from '@mantine/core';
import { useMergedRef } from '@mantine/hooks';
import { FormulaField } from '@vrplatform/ui-common';
import {
  ElementRef,
  ReactNode,
  forwardRef,
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import CodeEditor from 'react-simple-code-editor';
import AutoSizer from 'react-virtualized-auto-sizer';
import { ListChildComponentProps, VariableSizeList } from 'react-window';
import InfiniteLoader from 'react-window-infinite-loader';

type DropdownProps = {
  position?: FloatingPosition;
  withinPortal?: boolean;
  height?: number;
  width?: number | 'target';
  customAction?: {
    label: string;
    onSubmit: (searchContent: string) => void;
    icon?: React.ReactNode;
  };
};

type DataProps = {
  options: Maybe<FormulaField[]>;
  count: Maybe<number>;
  limit: number;
  loading: boolean;
  onFetchMore: (newLimit: number) => void;
  onSearchChange: (search: string) => void;
};

type BaseProps = {
  searchValue: string;
  editorRef: React.MutableRefObject<CodeEditor | null>;
  onSelectValue: (value: FormulaField) => void;
};

type Props = {
  data: DataProps;
  dropdownProps?: DropdownProps;
  baseProps: BaseProps;
};

interface ItemDataProps {
  highlightedIndex: number | undefined;
  options: FormulaField[];
  loading: boolean;
  onSelectValue: (value: FormulaField) => void;
}

type ReactWindowRef = ElementRef<typeof VariableSizeList<ItemDataProps>>;

export const FormulaDropdown = ({
  baseProps: { onSelectValue, editorRef, searchValue: search = '' },
  data: {
    options: o,
    count,
    limit: defaultLimit,
    loading,
    onFetchMore,
    onSearchChange,
  },
  dropdownProps,
}: Props) => {
  const { height: popoverHeight = 300, customAction } = dropdownProps || {};

  const { getSize, containerRef, setSize, windowWidth, sizeMap } =
    useReactWindowRefs();

  const { options, listHeight } = useFilterOptions({
    options: o,
    sizeMap,
  });

  const { buttonRefs, highlightedIndex, setHighlightedIndex } =
    useKeyboardNavigation({
      options,
      ref: containerRef.current,
      customActionSubmit: customAction?.onSubmit,
      search,
      editorRef,
    });

  useEffect(() => {
    setHighlightedIndex(0);
  }, [search, setHighlightedIndex]);

  const itemData = useMemo<ItemDataProps>(
    () => ({
      options,
      onSelectValue,
      highlightedIndex,
      loading: !!loading,
    }),
    [options, onSelectValue, highlightedIndex, loading]
  );

  useEffect(() => {
    if (onSearchChange) onSearchChange(search);
  }, [search, onSearchChange]);

  const hasNextPage =
    typeof count === 'number' ? count > options.length : loading;
  const itemCount = hasNextPage ? options.length + 1 : options.length;
  const isItemLoaded = (index: number) =>
    !hasNextPage || index < options.length;

  return (
    <Box>
      {search && (
        <Text
          size="xs"
          c="gray"
          fw={500}
          py="xs"
          px="xs"
          sx={(theme) => ({
            borderBottom: `1px solid ${theme.colors.neutral[2]}`,
          })}
        >
          {options.length} results for "{search}"
        </Text>
      )}
      {loading && options.length === 0 ? (
        <Stack my="xs" mx="xs" gap="xs">
          <Skeleton h={20} />
          <Skeleton h={20} />
          <Skeleton h={20} />
        </Stack>
      ) : !options.length && !loading ? (
        <Text my={14} ta="center" size={'0.75rem'} c="gray" fw={500}>
          No options found ...
        </Text>
      ) : (
        <Box
          sx={{
            height: '100%',
            maxHeight: rem(250),
            minHeight: listHeight < popoverHeight ? listHeight : popoverHeight,
          }}
        >
          <AutoSizer disableWidth>
            {({ height }) => (
              <InfiniteLoader
                itemCount={itemCount}
                isItemLoaded={isItemLoaded}
                loadMoreItems={
                  loading
                    ? () => {}
                    : (start) => onFetchMore(start + defaultLimit)
                }
              >
                {({ onItemsRendered, ref: listRef }) => {
                  return (
                    <List
                      buttonRefs={buttonRefs}
                      containerRef={containerRef}
                      getSize={getSize}
                      setSize={setSize}
                      height={height}
                      itemCount={itemCount}
                      itemData={itemData}
                      listRef={listRef}
                      onItemsRendered={onItemsRendered}
                      isItemLoaded={isItemLoaded}
                      windowWidth={windowWidth}
                    />
                  );
                }}
              </InfiniteLoader>
            )}
          </AutoSizer>
        </Box>
      )}

      {customAction && (
        <Box
          sx={(theme) => ({
            borderTop: `1px solid ${theme.colors.neutral[2]}`,
          })}
        >
          <Item
            label={customAction.label}
            onClick={() => customAction.onSubmit(search)}
            icon={customAction.icon}
            isFocused={highlightedIndex === -1}
            description={null}
          />
        </Box>
      )}
    </Box>
  );
};

type ListProps = {
  itemData: ItemDataProps;
  listRef: (ref: any) => void;
  containerRef: ReturnType<typeof useReactWindowRefs>['containerRef'];
  windowWidth: ReturnType<typeof useReactWindowRefs>['windowWidth'];
  setSize: ReturnType<typeof useReactWindowRefs>['setSize'];
  getSize: ReturnType<typeof useReactWindowRefs>['getSize'];
  itemCount: number;
  height: number;
  buttonRefs: React.MutableRefObject<Record<string, HTMLButtonElement>>;
  onItemsRendered: any;
  isItemLoaded: (index: number) => boolean;
};
const List = ({
  containerRef,
  listRef,
  height,
  onItemsRendered,
  itemCount,
  buttonRefs,
  getSize,
  itemData,
  setSize,
  windowWidth,
  isItemLoaded,
}: ListProps) => {
  const mergedRef = useMergedRef(listRef, containerRef);

  return (
    <VariableSizeList
      ref={mergedRef}
      height={height}
      width={'100%'}
      onItemsRendered={onItemsRendered}
      itemCount={itemCount}
      itemData={itemData}
      itemSize={getSize}
    >
      {({ data, index, style }) => {
        if (!isItemLoaded(index)) {
          return (
            <div
              key={data?.options[index]?.id}
              style={{ ...style, paddingInline: rem(10) }}
            >
              <Skeleton h={20} />
            </div>
          );
        }

        return (
          <ListItem
            key={data?.options[index]?.id}
            data={data}
            index={index}
            setSize={setSize}
            windowWidth={windowWidth}
            style={style}
            buttonRefs={buttonRefs}
          />
        );
      }}
    </VariableSizeList>
  );
};

const ListItem = ({
  data,
  index,
  windowWidth,
  setSize,
  style,
  buttonRefs,
}: ListChildComponentProps<ItemDataProps> & {
  setSize: ReturnType<typeof useReactWindowRefs>['setSize'];
  windowWidth: number;
  buttonRefs: React.MutableRefObject<Record<string, HTMLButtonElement>>;
}) => {
  const { options: arr } = data;
  const item = arr[index];
  const nextValue = arr[index + 1];

  const handleSetValue = useCallback(() => {
    data.onSelectValue(item);
  }, [item, data.onSelectValue]);

  const rowRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const spacing = nextValue ? 0 : 0;
    const height = rowRef.current?.getBoundingClientRect().height;

    const finalHeight = height || 0 + spacing;
    setSize(index, height ? finalHeight : height || 0);
  }, [setSize, index, windowWidth, nextValue]);

  return (
    <div key={data?.options[index]?.id} style={style}>
      <Box ref={rowRef}>
        <Item
          label={item.label}
          onClick={handleSetValue}
          isFocused={data.highlightedIndex === index}
          icon={null}
          ref={(ref) => {
            if (ref) {
              buttonRefs.current[item.id] = ref;
            }
          }}
          description={item.description}
          type={item.type}
        />
      </Box>
    </div>
  );
};

type ItemProps = {
  onClick: () => void;
  label: string;
  type?: string;
  description: string | ReactNode;
  isFocused: boolean;
  icon: React.ReactNode;
};
const Item = forwardRef<HTMLButtonElement, ItemProps>(
  ({ label, onClick, icon, type, isFocused, description }, ref) => {
    const { colors } = useMantineTheme();

    const getType = useCallback(() => {
      if (type === 'item') {
        return {
          name: 'Account',
          color: colors.blue[6],
        };
      }
      if (type === 'var') {
        return {
          name: 'Variable',
          color: colors.grape[6],
        };
      }
      if (type === 'field') {
        return {
          name: 'Field',
          color: colors.teal[6],
        };
      }
      if (type === 'collection') {
        return {
          name: 'Collection',
          color: colors.cyan[6],
        };
      }
      if (type === 'function') {
        return {
          name: 'Function',
          color: colors.green[6],
        };
      }

      return {
        name: type,
        color: colors.blue[6],
      };
    }, [type, colors]);

    const typeInfo = getType();

    return (
      <Box
        component="button"
        onClick={(event) => {
          event.preventDefault();
          onClick();
        }}
        onMouseDown={(event) => {
          event.preventDefault();
          onClick();
        }}
        ref={ref}
        type="button"
        sx={(theme) => {
          return {
            display: 'flex',
            flexDirection: 'row',
            flexWrap: 'nowrap',
            justifyContent: 'space-between',
            alignItems: 'center',
            paddingInline: theme.spacing.xs,
            paddingBlock: 5,
            border: 'none',
            width: '100%',
            textAlign: 'left',
            cursor: 'pointer',
            backgroundColor: isFocused
              ? theme.colors.neutral[1]
              : 'transparent',
            ':hover': {
              backgroundColor: theme.colors.neutral[1],
            },
          };
        }}
      >
        <Group wrap="nowrap" gap={rem(8)} w="100%">
          {icon}
          <Box>
            <Text component="span" display="block" size="sm">
              {label}
            </Text>
            {description &&
              isFocused &&
              (typeof description === 'string' ? (
                <Text component="span" c="gray" size="xs">
                  {description}
                </Text>
              ) : (
                description
              ))}
          </Box>
          {type && (
            <Text
              component="p"
              size="sm"
              m={0}
              ml="auto"
              c={typeInfo?.color}
              ta="right"
              sx={{
                flexShrink: 0,
              }}
            >
              {typeInfo?.name}
            </Text>
          )}
        </Group>
      </Box>
    );
  }
);

function useFilterOptions({
  options: o,
  sizeMap,
}: {
  options: Maybe<FormulaField[]>;
  sizeMap: React.MutableRefObject<{ [t: number]: number }>;
}) {
  const [previous, setPrevious] = useState<Maybe<FormulaField[]>>(null);
  const [listHeight, setListHeight] = useState(100);

  const { sorted } = useMemo(() => {
    const options = previous || [];

    const sorted: FormulaField[] = sortBy(options, (x) => {
      const order = ['var', 'field', 'collection', 'function', 'item'];

      const index = order.indexOf(x.type);

      return `${index === -1 ? 99 : index + 1}.${x.label}`;
    });

    return {
      sorted,
    };
  }, [previous]);

  useEffect(() => {
    if (Array.isArray(o)) setPrevious(o);
  }, [o]);

  useEffect(() => {
    const heights = sorted.map((_x, i) => {
      if (!sizeMap?.current?.[i]) return 33;
      else return sizeMap.current[i];
    });

    const totalHeight = sum(heights);
    setListHeight(totalHeight);
  }, [sorted, sizeMap]);
  return {
    options: sorted,
    listHeight,
  };
}

const useReactWindowRefs = () => {
  const [windowSize, setWindowSize] = useState([0, 0]);

  const listRef = useRef<ReactWindowRef>(null);
  const sizeMap = useRef<{ [t: number]: number }>({});

  const setSize = useCallback((index: number, size: number) => {
    sizeMap.current = { ...sizeMap.current, [index]: size };
    listRef.current?.resetAfterIndex(index);
  }, []);
  const getSize = (index: number) => {
    return sizeMap.current[index] || 33;
  };

  useLayoutEffect(() => {
    function updateSize() {
      setWindowSize([window.innerWidth, window.innerHeight]);
    }

    window.addEventListener('resize', updateSize);
    updateSize();

    return () => window.removeEventListener('resize', updateSize);
  }, []);

  const windowWidth = windowSize[0];

  return {
    windowWidth,
    containerRef: listRef,
    setSize,
    getSize,
    sizeMap,
  };
};

function useKeyboardNavigation({
  options,
  ref,
  customActionSubmit,
  search,
  editorRef: eventListenerRef,
}: {
  options: FormulaField[];
  editorRef: React.MutableRefObject<CodeEditor | null>;
  ref: ReactWindowRef | null;
  customActionSubmit:
    | NonNullable<DropdownProps['customAction']>['onSubmit']
    | undefined;
  search: string;
}) {
  const [highlightedIndex, setHighlightedIndex] = useState<number>();

  // const eventListenerRef = useRef<HTMLDivElement>(null);

  const buttonRefs = useRef<Record<string, HTMLButtonElement>>({});

  const scrollTo = (index: number) => {
    setHighlightedIndex(index);
    ref?.scrollToItem(index);
  };

  useEffect(() => {
    const handler = (e: KeyboardEvent) => {
      // if (e.target != containerRef.current) return;

      switch (e.code) {
        case 'Enter':
          e.preventDefault();
          // case 'Space':
          if (highlightedIndex !== undefined) {
            if (highlightedIndex === -1) {
              customActionSubmit?.(search);
            } else {
              const activeButton =
                buttonRefs.current[options[highlightedIndex].id];
              activeButton.click();
            }
          }
          break;
        case 'ArrowUp':
        case 'ArrowDown': {
          e.preventDefault();

          // when nothing is selected
          if (highlightedIndex === undefined && e.code === 'ArrowDown') {
            scrollTo(0);
            setHighlightedIndex(0);
            break;
          }
          if (
            highlightedIndex === undefined &&
            !!customActionSubmit &&
            e.code === 'ArrowUp'
          ) {
            setHighlightedIndex(-1);
            break;
          }

          // when custom action is selected
          if (highlightedIndex === -1) {
            if (e.code === 'ArrowUp') {
              scrollTo(options.length - 1);
            } else {
              scrollTo(0);
            }
            break;
          }

          const isDown = e.code === 'ArrowDown';
          const newValue = (highlightedIndex || 0) + (isDown ? 1 : -1);

          if (newValue >= 0 && newValue < options.length) scrollTo(newValue);
          else if (newValue === options.length || newValue === -1) {
            if (customActionSubmit) {
              setHighlightedIndex(-1);
            } else {
              scrollTo(isDown ? 0 : options.length - 1);
            }
          }
          break;
        }
      }
    };
    (eventListenerRef.current as any)?.addEventListener('keydown', handler);

    return () => {
      (eventListenerRef.current as any)?.removeEventListener(
        'keydown',
        handler
      );
    };
  }, [
    // eventListenerRef,
    options,
    ref,
    highlightedIndex,
    // setHighlightedIndex,
    customActionSubmit,
    scrollTo,
    search,
  ]);

  return {
    buttonRefs,
    highlightedIndex,
    setHighlightedIndex,
  };
}
