import { Tracking, useDashboard, useMe, useTracking } from '@finalytic/data';
import { day } from '@finalytic/utils';
import { PostHog, useActiveFeatureFlags } from 'posthog-js/react';
import { useEffect, useState } from 'react';
import { useIsWelcomeModalOpen } from './WelcomeUserModal';

function getFeatureSessionStorageKey(featureFlagName: string, userId: string) {
  return `ph-popup-${userId}-${featureFlagName}`;
}

function shouldShowPopup(featureFlagName: string, userId: string) {
  // The feature flag should have be disabled for the user once the popup has been shown
  // This is a second check for shorter-term preventing of the popup from showing
  const flagNotShownBefore = !localStorage.getItem(
    getFeatureSessionStorageKey(featureFlagName, userId)
  );

  return flagNotShownBefore;
}

function closePopUp(
  featureFlagName: string,
  userId: string,
  setShowPopup: (val: boolean) => void,
  posthog?: PostHog,
  track?: Tracking['track']
) {
  setShowPopup(false);
  localStorage.setItem(
    getFeatureSessionStorageKey(featureFlagName, userId),
    'true'
  );
  posthog?.people.set({ [`$${featureFlagName}`]: new Date().toDateString() });

  track?.('owner_tax_info_modal_submitted', { flag: featureFlagName });
}

function initPopUp(
  flag: string,
  userId: string,
  setActiveFlag: (val: string) => void,
  setShowPopup: (val: boolean) => void,
  track?: Tracking['track']
) {
  if (shouldShowPopup(flag, userId)) {
    setActiveFlag(flag);
    setShowPopup(true);

    track?.('owner_tax_info_modal_opened', { flag });
  }
}

const featureFlagKey = 'owner-tax-info';

export function useOwnerTaxInfoModal() {
  const [showPopup, setShowPopup] = useState(false);
  const [activeFlag, setActiveFlag] = useState<null | string>(null);
  const activeFlags = useActiveFeatureFlags();
  const isWelcomeModalOpen = useIsWelcomeModalOpen();
  const [dashboard] = useDashboard();
  const { id: userId, createdAt } = useMe();
  const { track, posthog } = useTracking();

  const isNewUser = createdAt && day().diff(createdAt, 'days') < 7;

  const isOwner = dashboard === 'owner';

  const preventOpen = isWelcomeModalOpen || !isOwner || isNewUser;

  useEffect(() => {
    if (!activeFlags) {
      return;
    }
    for (const flag of activeFlags) {
      const isEnabled = posthog?.isFeatureEnabled(flag);

      if (flag === featureFlagKey && isEnabled) {
        initPopUp(flag, userId, setActiveFlag, setShowPopup, track);
        return;
      }
    }
  }, [activeFlags, posthog, featureFlagKey, userId]);

  return {
    opened: preventOpen ? false : showPopup,
    close: () =>
      activeFlag &&
      closePopUp(activeFlag, userId, setShowPopup, posthog, track),
    featureFlagKey,
  };
}
