import { Icon } from '@finalytic/icons';
import { LoadingIndicator } from '@finalytic/ui';
import {
  Box,
  Card,
  Center,
  Text as MText,
  Tooltip,
  useMantineColorScheme,
} from '@mantine/core';
import { useDebouncedValue } from '@mantine/hooks';
import { Extension } from '@tiptap/core';
import Document from '@tiptap/extension-document';
import Mention from '@tiptap/extension-mention';
import Paragraph from '@tiptap/extension-paragraph';
import Placeholder from '@tiptap/extension-placeholder';
import Text from '@tiptap/extension-text';
import { EditorContent, FloatingMenu, useEditor } from '@tiptap/react';
import { type PropsWithChildren, useEffect, useMemo, useState } from 'react';
import type { InfiniteDataProps } from '../../select/SelectDropdown';
import { InfiniteDataContext } from './_context';
import { suggestion_config } from './_suggestion-config';

type EditorProps = {
  initialValue: string;
  onChange: (newValue: string | null) => Promise<any>;
  autoFocus?: boolean;
  queryData: InfiniteDataProps;
  disabled?: boolean;
  placeholder?: string;
  minHeight?: number | string;
  viewOnly?: boolean;
};

type BaseProps = {
  loading: boolean;
  error: boolean | string;
};

type InputFormulaProps = BaseProps & EditorProps;

export const InputFormulaTipTap = ({
  loading,
  error,
  disabled,
  minHeight,
  viewOnly,
  ...editorProps
}: InputFormulaProps) => {
  const [focused, setFocus] = useState(false);

  return (
    <Container
      disabled={!!disabled}
      error={!!error}
      loading={loading}
      focused={focused}
      placeholder={editorProps.placeholder}
      minHeight={minHeight}
      viewOnly={viewOnly}
    >
      <Editor
        {...editorProps}
        viewOnly={viewOnly}
        initialValue={editorProps.initialValue || ''}
        disabled={viewOnly || loading || disabled}
        setFocus={setFocus}
      />
    </Container>
  );
};

const Container = ({
  children,
  error,
  width,
  disabled,
  loading,
  focused,
  placeholder,
  minHeight,
  viewOnly,
}: PropsWithChildren<{
  error: boolean;
  width?: number;
  disabled: boolean;
  loading: boolean;
  focused: boolean;
  placeholder: string | undefined;
  minHeight?: number | string;
  viewOnly?: boolean;
}>) => {
  const { colorScheme } = useMantineColorScheme();
  return (
    <Box
      id="input-target"
      sx={(theme) => {
        const isDarkTheme = colorScheme === 'dark';

        const borderColor = error ? 'red' : theme.primaryColor;

        return {
          display: 'flex',
          flexWrap: 'nowrap',
          gap: theme.spacing.md,
          justifyContent: 'space-between',
          // alignItems: 'flex-start',
          width: width || undefined,
          border: '1px solid',
          minHeight: minHeight || '2.25rem',
          height: '100%',
          borderColor: viewOnly
            ? 'transparent'
            : focused
              ? theme.colors[borderColor][6]
              : error
                ? theme.colors.red[6]
                : theme.colors.gray[isDarkTheme ? 8 : 4],
          borderRadius: theme.radius.md,
          boxShadow:
            focused && !viewOnly
              ? `0px 0px 0px 2px ${theme.colors[borderColor][4]}40`
              : undefined,
          backgroundColor: viewOnly
            ? undefined
            : disabled
              ? isDarkTheme
                ? theme.colors.gray[8]
                : theme.colors.neutral[0]
              : isDarkTheme
                ? theme.colors.gray[9]
                : theme.white,
          '.formula-editor': {
            padding: `6px ${theme.spacing.xs} 5px`,
          },
          '.formula-editor, > div:first-of-type': {
            height: '100%',
            width: '100%',
            minHeight,
            flexGrow: 1,
            outline: 'none',
            overflow: 'hidden',
            cursor: disabled || loading || viewOnly ? undefined : 'pointer',
          },
          '.formula-suggestion': {
            borderRadius: theme.radius.md,
            backgroundColor: theme.colors[theme.primaryColor][1] + 90,
            color: theme.colors[theme.primaryColor][8],
            padding: '0.1rem 0.3rem',
            boxDecorationBreak: 'clone',
            fontSize: theme.fontSizes.xs,
            '&.green': {
              backgroundColor: theme.colors.green[1] + 90,
              color: theme.colors.green[9],
            },
            '&.purple': {
              backgroundColor: theme.colors.grape[1] + 90,
              color: theme.colors.grape[9],
            },
            '&.orange': {
              backgroundColor: theme.colors.orange[1] + 90,
              color: theme.colors.orange[9],
            },
          },
          '.tiptap p.is-editor-empty:first-of-type::before': {
            color: theme.colors.gray[isDarkTheme ? 4 : 5],
            fontSize: theme.fontSizes.xs,
            content: `"${placeholder}"` || 'attr(data-placeholder)',
            float: 'left',
            height: 0,
            pointerEvents: 'none',
          },
          p: {
            margin: 0,
            fontSize: theme.fontSizes.sm,
            lineHeight: theme.lineHeights.md,
          },
        };
      }}
    >
      {children}
      {loading ? (
        <LoadingIndicator size={'1rem'} mt={9} mr={9} />
      ) : (
        !viewOnly && (
          <Tooltip
            label={`Type "@" for auto-complete suggestions`}
            withinPortal
            withArrow
          >
            <Center
              w={16}
              mt={9}
              mr={12}
              sx={{ justifySelf: 'flex-end', alignSelf: 'flex-start' }}
            >
              <Icon
                icon="InfoIcon"
                size={16}
                color={({ colors }) => colors.gray[6]}
                strokeWidth={1.2}
              />
            </Center>
          </Tooltip>
        )
      )}
    </Box>
  );
};

type BaseEditorProps = {
  setFocus: (focus: boolean) => void;
};

const DisableKeys = Extension.create({
  addKeyboardShortcuts() {
    return {
      Enter: () => true,
      ArrowUp: () => true,
      ArrowDown: () => true,
    };
  },
});

const Editor = (props: EditorProps & BaseEditorProps) => {
  const [formula, setFormula] = useState(props.initialValue);
  const [debounced] = useDebouncedValue(formula, 5000);

  const editor = useEditor({
    autofocus: !!props.autoFocus,
    extensions: [
      DisableKeys,
      Document,
      Text,
      Paragraph,
      Placeholder.configure({
        showOnlyWhenEditable: false,
        placeholder: props.placeholder,
      }),
      Mention.configure({
        HTMLAttributes: {
          class: 'formula-suggestion',
        },
        suggestion: suggestion_config,
        renderHTML({ node }) {
          const getColor = () => {
            const val = node.attrs.id;
            const label = node.attrs.label;

            if (val.startsWith('acc.') || val.startsWith('itm.')) {
              if (label === 'Invalid account') return 'orange';

              return '';
            } else if (val.startsWith('reservation.')) {
              return 'green';
            } else if (val.startsWith('ca.')) {
              return 'orange';
            } else {
              return 'purple';
            }
          };

          return [
            'span',
            {
              class: `formula-suggestion ${getColor()}`.trim(),
            },
            `${node.attrs.label ?? node.attrs.id}`,
          ];
        },
        renderText({ node }) {
          return `"${node.attrs.id}"`;
        },
      }),
    ],
    editable: !props.disabled,
    editorProps: {
      attributes: {
        class: 'formula-editor',
      },
    },
    content: formula,
    onUpdate: ({ editor }) => {
      setFormula(editor.getText());
    },
    onFocus: () => props.setFocus(true),
    onBlur: ({ editor }) => {
      props.setFocus(false);
      const value = editor.getText();
      if (value !== props.initialValue) {
        props.onChange(value);
      }
    },
  });

  const value = useMemo<InfiniteDataProps>(
    () => props.queryData,
    [props.queryData]
  );

  useEffect(() => {
    if (debounced !== props.initialValue && editor?.isFocused) {
      props.onChange(debounced);
    }
  }, [debounced, editor?.isFocused, props.initialValue, props.onChange]);

  useEffect(() => {
    if (props.viewOnly || !editor?.getText().trim()) {
      setFormula(props.initialValue);
      editor?.commands.setContent(props.initialValue);
    }
  }, [props.viewOnly, props.initialValue]);

  useEffect(() => {
    // Setting inside hook doesnt seem to work, so using effect
    editor?.setEditable(!props.disabled);
  }, [props.disabled]);

  if (!editor) {
    return null;
  }

  return (
    <InfiniteDataContext.Provider value={value}>
      {editor && (
        <FloatingMenu
          editor={editor}
          tippyOptions={{
            duration: 100,
            offset: [30, 0],
            popperOptions: {
              strategy: 'fixed',
            },
          }}
        >
          <Card withBorder shadow="md" px="xs" py={3}>
            <MText component="span" size="xs">
              Type "@" for auto-complete suggestions
            </MText>
          </Card>
        </FloatingMenu>
      )}
      <EditorContent editor={editor} height={'100%'} width="100%" />
    </InfiniteDataContext.Provider>
  );
};
