import { useUser } from '@clerk/clerk-react';
import {
  Button,
  Input,
  InputAddress,
  InputSelect,
  InputWrapper,
} from '@finalytic/components';
import {
  gqlV2,
  useMe,
  useQuery,
  useTeamId,
  useTrpcMutation,
} from '@finalytic/data';
import { OwnerStatementsIcon } from '@finalytic/icons';
import { Modal, showErrorNotification } from '@finalytic/ui';
import {
  Box,
  Center,
  Checkbox,
  Group,
  Skeleton,
  Stack,
  Text,
  Title,
  rem,
  useMantineTheme,
} from '@mantine/core';
import {
  Address,
  OWNER_COMPANY_TYPES,
  getCountryByIsoCode,
  getOwnerAddress,
  getOwnerCompanyType,
  getStateByIsoCode,
  validateTin,
} from '@vrplatform/ui-common';
import { PostHogFeature } from 'posthog-js/react';
import { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useOwnerTaxInfoModal } from './useOwnerTaxInfoModal';

type Owner = NonNullable<ReturnType<typeof useOwnersQuery>['data']>[number];

const useOwnersQuery = (opened: boolean) => {
  const { id: meId } = useMe();

  return useQuery(
    (q, args) => {
      if (!args.meId) return [];

      return q
        .owners({
          where: {
            userAccesses: {
              userId: { _eq: args.meId },
              role: { _eq: 'admin' },
            },
          },
          order_by: [
            {
              type: 'desc_nulls_last',
            },
          ],
        })
        .map((owner) => ({
          id: owner.id,
          firstName: owner.firstName,
          name: owner.name,
          type: owner.type || 'individual',
          companyType: owner.companyType,
          addressId: owner.addressId,
          taxId: owner.taxId,
          address: getOwnerAddress(owner).values,
        }));
    },
    {
      skip: !opened || !meId,
      variables: {
        meId,
      },
    }
  );
};

export const OwnerTaxInfoModal = () => {
  const { primaryColor, colors } = useMantineTheme();
  const { opened, close: closeModal, featureFlagKey } = useOwnerTaxInfoModal();

  const { data, isLoading } = useOwnersQuery(opened);

  if (!opened || isLoading || data?.length === 0) return null;

  return (
    <PostHogFeature flag={featureFlagKey} match={true} fallback={null}>
      <Modal
        opened={opened}
        onClose={() => null}
        closeOnEscape={false}
        closeOnClickOutside
        withCloseButton={false}
        size={500}
        styles={{
          body: {
            paddingBlock: rem(40),
            paddingInline: rem(40),
          },
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: rem(48),
          }}
        >
          <Center>
            <OwnerStatementsIcon
              color={colors[primaryColor][6]}
              size={45}
              strokeWidth={1}
            />
          </Center>
          <Box>
            <Title ta="center" size={rem(20)} mb={rem(24)}>
              Update your details
            </Title>
            <Text color="neutral" ta="center" size="sm">
              Periodically, VRPlatform will ask you to check if all your details
              are still uptodate. We use these to generate your monthly owner
              statements, but also for year end tax statements.
            </Text>
          </Box>

          {isLoading ? (
            <Stack>
              <Skeleton height={30} />
              <Skeleton height={30} />
              <Skeleton height={30} />
            </Stack>
          ) : (
            <Content owners={data || []} closeModal={closeModal} />
          )}
        </Box>
      </Modal>
      //{' '}
    </PostHogFeature>
  );
};

const Content = ({
  owners,
  closeModal,
}: { owners: Owner[]; closeModal: () => void }) => {
  const [current, setCurrent] = useState(0);

  if (!owners.length)
    return (
      <>
        <Text component="p" ta="center">
          No owners found
        </Text>
        <Button variant="primary" onClick={closeModal}>
          Close
        </Button>
      </>
    );

  return (
    <Box>
      <OwnerForm
        owner={owners[current]}
        closeModal={closeModal}
        isLast={owners.length === current + 1}
        nextOwner={() => setCurrent((x) => x + 1)}
      />
      {owners.length > 1 && (
        <Group mt={rem(30)} wrap="nowrap" justify="center">
          {owners.map((v, index) => {
            return (
              <Box
                key={v.id}
                sx={(theme) => ({
                  height: 10,
                  width: 10,
                  borderRadius: 10,
                  backgroundColor:
                    index === current
                      ? theme.colors[theme.primaryColor][6]
                      : theme.colors.gray[2],
                })}
              />
            );
          })}
        </Group>
      )}
    </Box>
  );
};

function useOwnerMutation() {
  const [teamId] = useTeamId();
  const { mutate } = useTrpcMutation('updateOwner', {
    successMessage: {
      title: 'Success!',
      message: 'Details were updated.',
    },
  });

  return {
    mutate: ({
      firstName,
      id,
      lastName,
      address,
      addressId,
      taxId,
      type,
    }: {
      id: string;
      firstName: string;
      lastName: string;
      type: gqlV2.owner_type_enum;
      addressId: string | undefined;
      address: Address | undefined;
      taxId: string | undefined;
    }) => {
      return mutate({
        ownerId: id || 'x',
        tenantId: teamId,
        input: {
          firstName,
          lastName,
          type,
          taxId: taxId || null,
          addressId: addressId,
          address: !address
            ? null
            : {
                line1: address.line1.trim(),
                line2: address.line2?.trim() || '',
                city: address.city.trim(),
                postcode: address.postcode.trim(),
                countryCode: address.countryCode,
                stateCode: address.stateCode || '',
                country: getCountryByIsoCode(address.countryCode)?.name || '',
                state:
                  getStateByIsoCode(address.stateCode, address.countryCode)
                    ?.name || '',
              },
        },
      });
    },
  };
}

type FormInputs = {
  firstName: string;
  lastName: string;
  ownerType: gqlV2.owner_type_enum;
  companyType: string;
  address?: Address;
  taxId?: string;
};

const trapSpacesForRequiredFields = (value: string) =>
  !!value.trim() || 'This field is required';

const OwnerForm = ({
  isLast,
  owner,
  nextOwner,
  closeModal,
}: {
  owner: Owner;
  closeModal: () => void;
  isLast: boolean;
  nextOwner: () => void;
}) => {
  const { user } = useUser();

  const { address, companyType, firstName, id, name, type, addressId, taxId } =
    owner;

  const { mutate } = useOwnerMutation();

  const [taxDisabled, setTaxDisabled] = useState(false);

  const { control, handleSubmit, formState, setValue } = useForm<FormInputs>({
    // mode: 'onChange',
    values: {
      firstName: firstName || '',
      lastName: name || '',
      companyType: companyType || '',
      ownerType: type || 'individual',
      address: {
        city: address.city || '',
        line1: address.line1 || '',
        line2: address.line2 || '',
        postcode: address.postcode || '',
        stateCode: address.stateCode || '',
        countryCode: address.countryCode || '',
      },
      taxId,
    },
  });

  const submit = async ({
    firstName,
    lastName,
    address: newAddress,
    taxId: newTaxId,
    ownerType,
  }: FormInputs) => {
    if (!user) {
      return showErrorNotification({
        title: 'Internal Error',
        message: 'User not found. Please refresh the page.',
        color: 'yellow',
      });
    }

    await mutate({
      id,
      firstName,
      lastName,
      address: newAddress,
      addressId: addressId,
      taxId: newTaxId,
      type: ownerType,
    }).then(() => {
      if (isLast) {
        closeModal();
      } else {
        nextOwner();
        setTaxDisabled(false);
      }
    });
  };

  const isCompany = type === 'company';

  return (
    <Box
      component="form"
      onSubmit={handleSubmit(submit, (errors) => {
        console.log(errors);
      })}
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: rem(48),
      }}
    >
      <Stack>
        <Group
          wrap="nowrap"
          align="flex-start"
          sx={{
            '> div': {
              flex: 1,
            },
          }}
        >
          {!isCompany && (
            <Controller
              control={control}
              name="firstName"
              defaultValue=""
              rules={{
                required: 'Please enter your first name.',
                validate: trapSpacesForRequiredFields,
              }}
              render={({
                field: { value, onChange, name },
                fieldState: { error },
              }) => {
                return (
                  <InputWrapper
                    label={'First name'}
                    error={error?.message}
                    required
                  >
                    <Input
                      value={value}
                      setValue={onChange}
                      name={name}
                      placeholder="First name"
                      error={error?.message}
                      autoComplete="given-name"
                      type="text"
                      required
                    />
                  </InputWrapper>
                );
              }}
            />
          )}
          <Controller
            control={control}
            name="lastName"
            defaultValue=""
            rules={{
              required: 'Please enter your last name.',
              validate: trapSpacesForRequiredFields,
            }}
            render={({
              field: { value, onChange, name },
              fieldState: { error },
            }) => {
              return (
                <InputWrapper
                  label={isCompany ? 'Company name' : 'Last name'}
                  error={error?.message}
                  required
                >
                  <Input
                    value={value}
                    setValue={onChange}
                    name={name}
                    placeholder={isCompany ? 'Company name' : 'Last name'}
                    error={error?.message}
                    autoComplete={isCompany ? 'off' : 'family-name'}
                    type="text"
                    required
                  />
                </InputWrapper>
              );
            }}
          />
        </Group>

        {isCompany && (
          <Controller
            control={control}
            name="companyType"
            rules={{
              required: 'Company type is required',
            }}
            render={({ field, fieldState: { error } }) => (
              <InputWrapper
                label="Company type"
                required
                error={error?.message}
              >
                <InputSelect
                  data={{
                    options: OWNER_COMPANY_TYPES,
                    sort: null,
                  }}
                  type="single"
                  value={getOwnerCompanyType(field.value)}
                  inputProps={{
                    placeholder: 'Company type',
                    error: !!error,
                  }}
                  dropdownProps={{ width: 'target' }}
                  setValue={(e) => {
                    if (e?.value) {
                      field.onChange(e.value);
                    }
                  }}
                />
              </InputWrapper>
            )}
          />
        )}

        <Controller
          control={control}
          name="address"
          rules={{
            validate: (value) => {
              if (!value) {
                return 'Please enter your address';
              }

              if (!value.line1?.trim()) {
                return 'Please enter your address';
              }

              if (!value.city?.trim()) {
                return 'Please enter your city';
              }

              if (!value.stateCode?.trim()) {
                return 'Please enter your state';
              }

              if (!value.countryCode?.trim()) {
                return 'Please enter your country';
              }

              if (!value.postcode?.trim()) {
                return 'Please enter your postal code';
              }

              return true;
            },
          }}
          render={({ field: { value, onChange }, fieldState }) => {
            return (
              <InputWrapper
                error={fieldState.error?.message}
                label="Address"
                required
              >
                <InputAddress
                  value={{
                    city: value?.city || '',
                    line1: value?.line1 || '',
                    line2: value?.line2 || '',
                    postcode: value?.postcode || '',
                    stateCode: value?.stateCode || '',
                    countryCode: value?.countryCode || '',
                  }}
                  required
                  onChange={onChange}
                  dropdownProps={{
                    withinPortal: true,
                    position: 'right-start',
                  }}
                  inputProps={{
                    error: !!fieldState.error?.message,
                  }}
                />
              </InputWrapper>
            );
          }}
        />
        <Controller
          control={control}
          name="taxId"
          defaultValue=""
          rules={{
            required: taxDisabled
              ? undefined
              : 'Tax Identification Number is required',
            validate: taxDisabled ? undefined : (v) => validateTin(v),
          }}
          render={({ field, fieldState: { error } }) => {
            return (
              <InputWrapper
                label={'Tax Identification Number (TIN)'}
                error={error?.message}
                required={!taxDisabled}
              >
                <Input
                  placeholder="TIN"
                  error={error?.message}
                  type="number"
                  {...field}
                  required={!taxDisabled}
                  disabled={taxDisabled}
                  autoComplete="off"
                />
              </InputWrapper>
            );
          }}
        />
        <Checkbox
          label="I do not have a TIN"
          size="xs"
          checked={taxDisabled}
          onChange={() => {
            setTaxDisabled((e) => !e);
            setValue('taxId', '');
          }}
        />
      </Stack>

      <Button variant="primary" type="submit" loading={formState.isSubmitting}>
        {isLast ? 'Save changes' : 'Save and continue'}
      </Button>
    </Box>
  );
};
